import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'

export const Container = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 ${themeGet('space.5')}px;
  width: 100%;
  min-height: ${themeGet('sizes.header')}px;
  align-items: center;
  transition: all ease ${themeGet('defaultTransitionTime')};
  background: ${themeGet('colors.primary500')};
  color: ${themeGet('colors.white')};

  overflow-y: scroll;
`

export const ChevronLeftIcon = styled(ChevronLeft)<ColorProps>`
  ${color}
`

export const LogoutIcon = styled(Logout)<ColorProps>`
  ${color}
`

export const SettingsIcon = styled(Settings)<ColorProps>`
  ${color}
`
