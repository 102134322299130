import { useCallback, useMemo } from 'react'

import { useMutation } from '@apollo/client'

import {
  UpdateSystemSettingsDocument,
  UpdateSystemSettingsMutationVariables,
} from 'GraphQL/Main/TypedDocuments'
import SystemSettingsUpdater from 'GraphQL/Main/Updaters/settings'

import toast from 'Services/Toast'

function useSystemSettings() {
  const [updateSystemSettingsMutation, updateMutationResult] = useMutation(
    UpdateSystemSettingsDocument,
  )

  const updateMutation = useCallback(
    async (variables: UpdateSystemSettingsMutationVariables) => {
      try {
        const result = await updateSystemSettingsMutation({
          variables,
          update: (cache, { data }) =>
            SystemSettingsUpdater.update({
              cache,
              systemSettings: data?.updateSystemSettings,
            }),
        })

        const newSystemSettings = result.data?.updateSystemSettings

        if (!newSystemSettings) {
          return null
        }

        toast.success({
          text: 'Settings successfully updated!',
        })

        return newSystemSettings
      } catch (error) {
        toast.error({ text: 'error' })
        return null
      }
    },
    [updateSystemSettingsMutation],
  )

  const { updateSystemSettings } = useMemo(
    () => ({
      updateSystemSettings: {
        mutate: updateMutation,
        loading: updateMutationResult.loading,
      },
    }),
    [updateMutationResult, updateMutation],
  )

  return { updateSystemSettings }
}

export default useSystemSettings
