import React from 'react'

import { Column } from 'Components/UI'

import useResponsiveLayout from 'Hooks/useResponsiveLayout'

function TableContainer({ children }) {
  const { isMobilePortrait, isWide } = useResponsiveLayout()

  return (
    <Column
      fullHeight
      fullWidth
      gap={12}
      maxWidth={isWide ? 1100 : 900}
      px={isMobilePortrait ? 4 : 0}
    >
      {children}
    </Column>
  )
}

export default TableContainer
