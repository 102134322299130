import { RouteConfig } from 'react-router-config'

import { UserFragment, UserRole } from 'GraphQL/Main/TypedDocuments'

import { RootLayout } from 'Containers/Layout'
import NotFound from 'Containers/Pages/NotFound'

import adminRoutes from './admin'
import authRoutes from './auth'

function routes(user?: UserFragment) {
  const allRoutes: RouteConfig[] = []

  if (!user) {
    allRoutes.push(authRoutes())
  } else if (user?.role === UserRole.SuperAdmin) {
    allRoutes.push(adminRoutes())
  }

  return [
    {
      component: RootLayout,
      routes: [...allRoutes, { component: NotFound }],
    },
  ]
}

export { routes }
