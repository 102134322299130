import styled from 'styled-components'
import {
  height,
  HeightProps,
  margin,
  MarginProps,
  width,
  WidthProps,
} from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { createGetParam } from 'Theme'

interface VariantProps {
  light?: boolean
  contrast?: boolean
}

const variants = {
  color: {
    default: themeGet('colors.divider.default'),
    light: themeGet('colors.divider.light'),
    contrast: themeGet('colors.divider.contrast'),
  },
}

const getVariantsParam = createGetParam<typeof variants, VariantProps>(variants)

type DividerProps = WidthProps & HeightProps & MarginProps & VariantProps

const Divider = styled.div<DividerProps>`
  border-left: 1px solid ${getVariantsParam('color')};
  border-top: 1px solid ${getVariantsParam('color')};

  &:first-child,
  &:last-child,
  & + & {
    display: none;
  }

  ${width}
  ${height}
  ${margin}
`

export default Divider
