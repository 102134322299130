import { StoreonModule } from 'storeon'

import Shared from 'Services/Shared'

const KEY = 'passwordResetState'

interface StateValues {
  email?: string
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'passwordResetState/set': StateValues
  'passwordResetState/clear': void
}

export const INITIAL_STATE: StateValues = {
  email: undefined,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))
  store.on('passwordResetState/set', (state, payload) => {
    return {
      [KEY]: {
        ...state[KEY],
        ...payload,
      },
    }
  })
  store.on('passwordResetState/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

function getState() {
  return Shared.getStore().get()[KEY]
}

function getEmail() {
  return getState().email
}

function setEmail(email?: string) {
  Shared.getStore().dispatch('passwordResetState/set', { email })
}

function clear() {
  Shared.getStore().dispatch('passwordResetState/clear')
}

const PasswordResetState = {
  getEmail,
  setEmail,
  clear,
}

export { KEY, module, PasswordResetState }
export type { Events, State }
