import { LinkProps } from 'react-router-dom'

import styled from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import MaterialButton, { ButtonProps } from '@mui/material/Button'

import { gap, GapProps } from 'Services/Styles'

import { createGetParam } from 'Theme'

interface VariantColorProps {
  default?: boolean
  secondary?: boolean
  danger?: boolean
  success?: boolean
}

interface VariantSizeProps {
  small?: boolean
}

type Props = MarginProps &
  LayoutProps &
  ButtonProps &
  Partial<LinkProps> &
  VariantColorProps &
  VariantSizeProps &
  GapProps

const colorParams = {
  background: {
    default: themeGet('colors.primary500'),
    secondary: themeGet('colors.transparent'),
    danger: themeGet('colors.danger500'),
    success: themeGet('colors.success400'),
  },
  color: {
    default: themeGet('colors.white'),
    secondary: themeGet('colors.primary500'),
    danger: themeGet('colors.white'),
    success: themeGet('colors.white'),
  },
  hover: {
    default: themeGet('colors.primary400'),
    secondary: themeGet('colors.divider.default'),
    danger: themeGet('colors.danger400'),
    success: themeGet('colors.success300'),
  },
  disabledBackground: {
    default: themeGet('colors.neutral600'),
    secondary: themeGet('colors.transparent'),
    danger: themeGet('colors.neutral500'),
    success: themeGet('colors.success900'),
  },
  disabledColor: {
    default: themeGet('colors.neutral500'),
    secondary: themeGet('colors.text.muted'),
    danger: themeGet('colors.danger600'),
    success: themeGet('colors.success600'),
  },
}

const sizeParams = {
  height: {
    default: 56,
    small: themeGet('space.10'),
  },
}

const getSizeParam = createGetParam<typeof sizeParams, VariantSizeProps>(
  sizeParams,
)
const getColorParam = createGetParam<typeof colorParams, VariantColorProps>(
  colorParams,
)

const Button = styled(MaterialButton).withConfig({
  shouldForwardProp: prop =>
    !['secondary', 'small', 'danger', 'success'].includes(prop),
})<Props>`
  &.MuiButton-root {
    height: ${getSizeParam('height')}px;
    padding: 0 ${themeGet('space.6')}px;
    font-size: ${themeGet('fontSizes.1')}px;
    font-weight: ${themeGet('fontWeights.1')};
    color: ${getColorParam('color')};
    background-color: ${getColorParam('background')};

    &:hover {
      background-color: ${getColorParam('hover')};
      border-color: ${getColorParam('hover')};
    }

    &:disabled {
      cursor: default;
      color: ${getColorParam('disabledColor')};
      background-color: ${getColorParam('disabledBackground')};
    }

    ${margin}
    ${layout}
    ${gap}
  }
`

export default Button
