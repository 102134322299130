import { DateRange } from 'react-day-picker'

import { DateTime } from 'luxon'

export function toRange(from?: string, to?: string): DateRange | undefined {
  if (!from || !to) return undefined

  const fromDateTime = DateTime.fromISO(from)
  const fromDate = fromDateTime.isValid ? fromDateTime.toJSDate() : undefined
  const toDateTime = DateTime.fromISO(to)
  const toDate = fromDateTime.isValid ? toDateTime.toJSDate() : undefined
  return fromDate && { from: fromDate, to: toDate }
}
