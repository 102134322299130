interface PromiseHandler<T, R> {
  (item: T, index: number): Promise<T | R | void | null | undefined>
}

function forEachPromise<T, R>(arr: T[], handler: PromiseHandler<T, R>) {
  return arr.reduce(
    (current: Promise<any>, item: T, index: number) =>
      current.then(() => handler(item, index)),
    Promise.resolve(),
  )
}

function sleep(ms: number): Promise<any> {
  return new Promise<any>(resolve => {
    setTimeout(resolve, ms)
  })
}

export default { forEachPromise, sleep }
