import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Form } from 'react-final-form'

import { useQuery } from '@apollo/client'
import { Switch, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { validate } from 'validate.js'

import { Header } from 'Components/Blocks'
import {
  Button,
  Column,
  Divider,
  InputField,
  LinkButton,
  Loader,
  Row,
  Text,
} from 'Components/UI'

import { API, WEB3 } from 'Config'

import { SystemSettingsDocument } from 'GraphQL/Main/TypedDocuments'

import useResponsiveLayout from 'Hooks/useResponsiveLayout'
import useSystemSettings from 'Hooks/useSystemSettings'

import { useScopedI18n } from 'Services/I18n'

import { List, ListItem, StyledForm } from './styles'
import {
  Field,
  FormValues,
  IgnoreIncomingCallsTypes,
  SolutionKind,
} from './types'

function AdminSettings() {
  const s = useScopedI18n('settings')
  const { isWide } = useResponsiveLayout()

  const [enableIgnoreIncomingCalls, setEnableIgnoreIncomingCalls] =
    useState<IgnoreIncomingCallsTypes>()

  const [invalidSearchNotificationToggle, setInvalidSearchNotificationToggle] =
    useState<boolean>(false)

  const [thresholdNotificationToggle, setThresholdNotificationToggle] =
    useState<boolean>(false)

  const { data: systemSettingsData, loading } = useQuery(SystemSettingsDocument)

  const systemSettings = useMemo(
    () => systemSettingsData?.systemSettings,
    [systemSettingsData],
  )

  useEffect(() => {
    if (loading) {
      return
    }

    if (!systemSettings?.ignoreIncomingCalls) {
      setEnableIgnoreIncomingCalls({
        kind: SolutionKind.Polygon,
        enable: true,
      })
    } else if (systemSettings?.ignoreIncomingCalls) {
      setEnableIgnoreIncomingCalls({
        kind: SolutionKind.Off,
        enable: false,
      })
    }

    setThresholdNotificationToggle(!!systemSettings?.thresholdNotification)

    setInvalidSearchNotificationToggle(
      !!systemSettings?.invalidSearchNotification,
    )
  }, [loading, systemSettings])

  const handleToggleIgnoreIncomingCalls = useCallback(event => {
    if (event.target.value === 'polygon') {
      setEnableIgnoreIncomingCalls({
        kind: SolutionKind.Polygon,
        enable: true,
      })
    } else if (event.target.value === 'off') {
      setEnableIgnoreIncomingCalls({ kind: SolutionKind.Off, enable: false })
    }
  }, [])

  const handleToggleInvalidSearchNotification = useCallback(event => {
    setInvalidSearchNotificationToggle(event.target.checked)
  }, [])

  const handleToggleThresholdNotification = useCallback(event => {
    setThresholdNotificationToggle(event.target.checked)
  }, [])

  const handleOpenLink = useCallback(
    (event: MouseEvent<HTMLElement>, url: string) => {
      event.stopPropagation()
      window.open(url, '_blank')
    },
    [],
  )

  const initialValues = useMemo(
    () => ({
      [Field.ThresholdAmount]: systemSettings?.thresholdAmount,
      [Field.SMTPTo]: systemSettings?.smtpTo,
      [Field.SMTPUser]: systemSettings?.smtpUser,
      [Field.SMTPSenderName]: systemSettings?.smtpSenderName,
      [Field.SMTPPassword]: systemSettings?.smtpPassword,
      [Field.SMTPHost]: systemSettings?.smtpHost,
      [Field.SMTPPort]: systemSettings?.smtpPort,
    }),
    [systemSettings],
  )

  const validateForm = useMemo(() => {
    const constraints = {
      [Field.ThresholdAmount]: {
        numericality: {
          onlyInteger: false,
          greaterThanOrEqualTo: 0,
          lessThan: 999999,
          notGreaterThanOrEqualTo: `${s(
            'validations.notGreaterThanOrEqualTo',
          )}`,
          notLessThan: `${s('validations.notLessThan')}`,
        },

        presence: {
          message: `${s('validations.cantBeEmpty')}`,
        },
      },
    }
    return (values: FormValues) => validate(values, constraints)
  }, [s])

  const { updateSystemSettings } = useSystemSettings()

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await updateSystemSettings.mutate({
        ignoreIncomingCalls:
          !enableIgnoreIncomingCalls?.enable ??
          !values[Field.IgnoreIncomingCalls]?.enable,
        invalidSearchNotification:
          invalidSearchNotificationToggle ??
          !values[Field.InvalidSearchNotification],
        thresholdNotification:
          thresholdNotificationToggle ?? !values[Field.ThresholdNotification],
        thresholdAmount: Number(values[Field.ThresholdAmount]),
        smtpUser: values[Field.SMTPUser],
        smtpPassword: values[Field.SMTPPassword],
        smtpSenderName: values[Field.SMTPSenderName],
        smtpTo: values[Field.SMTPTo],
        smtpHost: values[Field.SMTPHost],
        smtpPort: values[Field.SMTPPort],
      })
    },
    [
      updateSystemSettings,
      enableIgnoreIncomingCalls?.enable,
      invalidSearchNotificationToggle,
      thresholdNotificationToggle,
    ],
  )

  const walletAmount = Number(systemSettings?.walletAmount ?? 0).toFixed(2)

  const renderForm = useCallback(
    ({ handleSubmit, submitting }) => (
      <StyledForm onSubmit={handleSubmit}>
        <Column gap={4} p={8}>
          <Text header4>{s('sections.general')}</Text>

          <ToggleButtonGroup
            color="primary"
            exclusive
            fullWidth
            size="medium"
            value={enableIgnoreIncomingCalls?.kind}
            onChange={handleToggleIgnoreIncomingCalls}
          >
            <ToggleButton value={SolutionKind.Polygon}>
              {s('fields.polygon')}
            </ToggleButton>
            <ToggleButton disabled value={SolutionKind.LacChain}>
              {s('fields.lacChain')}
            </ToggleButton>
            <ToggleButton value={SolutionKind.Off}>
              {s('fields.off')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Column>
        <Divider width="100%" />

        <Column gap={3} p={8}>
          <Text header4>{s('sections.credentials')}</Text>
          <List>
            <ListItem>
              <Text header5>{s('fields.wallet')}</Text>
              <LinkButton
                type="button"
                onClick={event =>
                  handleOpenLink(
                    event,
                    `${WEB3.POLYGONSCAN_LINK_ADDRESS}${systemSettings?.wallet}`,
                  )
                }
              >
                <Text action3>{systemSettings?.wallet} &rarr;</Text>
              </LinkButton>
            </ListItem>
            <ListItem>
              <Text header5>{s('fields.smartContractAddress')}</Text>
              <LinkButton
                type="button"
                onClick={event =>
                  handleOpenLink(
                    event,
                    `${WEB3.POLYGONSCAN_LINK_ADDRESS}${systemSettings?.smartContractAddress}`,
                  )
                }
              >
                <Text action3>
                  {systemSettings?.smartContractAddress} &rarr;{' '}
                </Text>
              </LinkButton>
            </ListItem>
            <ListItem>
              <Text header5>{s('fields.apiEndpoint')}</Text>
              <LinkButton
                type="button"
                onClick={event => handleOpenLink(event, API.URL)}
              >
                <Text action3>{API.URL} &rarr;</Text>
              </LinkButton>
            </ListItem>
          </List>
        </Column>
        <Divider width="100%" />

        <Column p={8}>
          <Text header4 mb={3}>
            {s('sections.smtp')}
          </Text>

          <Row fullWidth gap={6} mt={6}>
            <InputField
              disabled={submitting}
              displayError
              label={s('fields.smtp.user')}
              name={Field.SMTPUser}
              placeholder={s('fields.smtp.user')}
              width={1}
              onChange={event => event.target.value.trim()}
            />

            <InputField
              disabled={submitting}
              displayError
              label={s('fields.smtp.to')}
              name={Field.SMTPTo}
              placeholder={s('fields.smtp.to')}
              width={1}
              onChange={event => event.target.value.trim()}
            />
          </Row>

          <Row fullWidth gap={6} mt={6}>
            <InputField
              disabled={submitting}
              displayError
              label={s('fields.smtp.password')}
              name={Field.SMTPPassword}
              placeholder={s('fields.smtp.password')}
              type="password"
              width={1}
              onChange={event => event.target.value.trim()}
            />

            <InputField
              disabled={submitting}
              displayError
              label={s('fields.smtp.host')}
              name={Field.SMTPHost}
              placeholder={s('fields.smtp.host')}
              width={1}
              onChange={event => event.target.value.trim()}
            />
          </Row>

          <Row fullWidth gap={6} mt={6}>
            <InputField
              disabled={submitting}
              displayError
              label={s('fields.smtp.senderName')}
              name={Field.SMTPSenderName}
              placeholder={s('fields.smtp.senderName')}
              width={1}
              onChange={event => event.target.value.trim()}
            />

            <InputField
              disabled={submitting}
              displayError
              label={s('fields.smtp.port')}
              name={Field.SMTPPort}
              placeholder={s('fields.smtp.port')}
              width={1}
              onChange={event => event.target.value.trim()}
            />
          </Row>
        </Column>

        <Divider width="100%" />

        <Column p={8}>
          <Text header4 mb={3}>
            {s('sections.notifications')}
          </Text>

          <Column>
            <Text mb={3}>{s('fields.emailNotificationFor')}</Text>

            <Row center>
              <Switch
                checked={invalidSearchNotificationToggle}
                onChange={handleToggleInvalidSearchNotification}
              />
              <Text caption1>{s('fields.invalidSearchNotification')}</Text>
            </Row>

            <Column gap={6}>
              <Row center>
                <Switch
                  checked={thresholdNotificationToggle}
                  onChange={handleToggleThresholdNotification}
                />

                <Text caption1 mr={6}>
                  {s('fields.gasThresholdReached')}
                </Text>
              </Row>
              <InputField
                disabled={!thresholdNotificationToggle ?? submitting}
                displayError
                label={`${s('fields.thresholdAmount')} (MATIC)`}
                mt={4}
                name={Field.ThresholdAmount}
                placeholder={s('fields.thresholdAmount')}
                type="number"
                onChange={event => Number(event.target.value.trim())}
              />
            </Column>
          </Column>
        </Column>
        <Divider width="100%" />

        <Column gap={3} p={8}>
          <Text header4>{s('sections.gasMeter')}</Text>
          <Text caption1>
            {s('fields.currentAmount')}{' '}
            <strong>
              {walletAmount ?? ''} {s('fields.matic')}
            </strong>
          </Text>
        </Column>
        <Divider width="100%" />

        <Button disabled={submitting} m={8} type="submit">
          {s('actions.updateSettings')}
        </Button>
      </StyledForm>
    ),
    [
      systemSettings,
      enableIgnoreIncomingCalls?.kind,
      invalidSearchNotificationToggle,
      thresholdNotificationToggle,
      walletAmount,
      s,
      handleToggleIgnoreIncomingCalls,
      handleToggleInvalidSearchNotification,
      handleToggleThresholdNotification,
      handleOpenLink,
    ],
  )

  if (loading) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column>
      <Column center>
        <Header isBackButtonShown />

        <Column fullWidth maxWidth={750} my={6} p={isWide ? 0 : 6}>
          <Text header2 mb={6}>
            {s('settings')}
          </Text>

          <Form
            initialValues={initialValues}
            render={renderForm}
            validate={validateForm}
            onSubmit={handleSubmit}
          />
        </Column>
      </Column>
    </Column>
  )
}

export default AdminSettings
