import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import { margin, MarginProps, width, WidthProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

type Props = MarginProps & WidthProps

const Link = styled(NavLink)<Props>`
  font-size: ${themeGet('fontSizes.2')}px;
  font-weight: ${themeGet('fontWeights.1')};
  color: ${themeGet('colors.primary500')};
  text-decoration: none;

  &:enabled:hover {
    color: ${themeGet('colors.primary300')};
  }

  &:enabled:active {
    color: ${themeGet('colors.primary500')};
  }

  &:disabled {
    cursor: default;
    color: ${themeGet('colors.text.muted')};
  }

  ${margin}
  ${width}
`

export default Link
