import styled, { css } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

interface CustomColorProps {
  primary?: boolean
  body?: boolean
  danger?: boolean
  heading?: boolean
  important?: boolean
  inverse?: boolean
  muted?: boolean
  success?: boolean
  warning?: boolean
}

const primaryCss = ({ primary }: CustomColorProps) =>
  primary &&
  css`
    color: ${themeGet('colors.primary500')};
  `

const bodyCss = ({ body }: CustomColorProps) =>
  body &&
  css`
    color: ${themeGet('colors.text.body')};
  `

const dangerCss = ({ danger }: CustomColorProps) =>
  danger &&
  css`
    color: ${themeGet('colors.danger500')};
  `

const headingCss = ({ heading }: CustomColorProps) =>
  heading &&
  css`
    color: ${themeGet('colors.text.heading')};
  `

const importantCss = ({ important }: CustomColorProps) =>
  important &&
  css`
    color: ${themeGet('colors.text.important')};
  `

const inverseCss = ({ inverse }: CustomColorProps) =>
  inverse &&
  css`
    color: ${themeGet('colors.white')};
    ::selection {
      background: ${themeGet('colors.white')};
      color: ${themeGet('colors.black')};
    }
  `

const mutedCss = ({ muted }: CustomColorProps) =>
  muted &&
  css`
    color: ${themeGet('colors.text.muted')};
  `

const successCss = ({ success }: CustomColorProps) =>
  success &&
  css`
    color: ${themeGet('colors.success500')};
  `

const warningCss = ({ warning }: CustomColorProps) =>
  warning &&
  css`
    color: ${themeGet('colors.warning500')};
  `

interface CustomTypographyProps {
  action1?: boolean
  action2?: boolean
  action3?: boolean
  action4?: boolean
  action5?: boolean
  body1?: boolean
  body2?: boolean
  body3?: boolean
  body4?: boolean
  body5?: boolean
  caption1?: boolean
  caption2?: boolean
  caption3?: boolean
  caption4?: boolean
  header1?: boolean
  header2?: boolean
  header3?: boolean
  header4?: boolean
  header5?: boolean
  header6?: boolean
  subheader1?: boolean
  subheader2?: boolean
  subheader3?: boolean
  subheader4?: boolean
}

const action1Css = ({ action1 }: CustomTypographyProps) =>
  action1 &&
  css`
    font-size: ${themeGet('fontSizes.3')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 160%;
  `

const action2Css = ({ action2 }: CustomTypographyProps) =>
  action2 &&
  css`
    font-size: 18px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 155%;
  `

const action3Css = ({ action3 }: CustomTypographyProps) =>
  action3 &&
  css`
    font-size: ${themeGet('fontSizes.2')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 150%;
  `

const action4Css = ({ action4 }: CustomTypographyProps) =>
  action4 &&
  css`
    font-size: ${themeGet('fontSizes.1')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 143%;
  `

const action5Css = ({ action5 }: CustomTypographyProps) =>
  action5 &&
  css`
    font-size: ${themeGet('fontSizes.0')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 133%;
  `

const body1Css = ({ body1 }: CustomTypographyProps) =>
  body1 &&
  css`
    font-size: ${themeGet('fontSizes.3')}px;
    font-weight: ${themeGet('fontWeights.0')};
    line-height: 180%;
  `

const body2Css = ({ body2 }: CustomTypographyProps) =>
  body2 &&
  css`
    font-size: 18px;
    font-weight: ${themeGet('fontWeights.0')};
    line-height: 178%;
  `

const body3Css = ({ body3 }: CustomTypographyProps) =>
  body3 &&
  css`
    font-size: ${themeGet('fontSizes.2')}px;
    font-weight: ${themeGet('fontWeights.0')};
    line-height: 175%;
  `

const body4Css = ({ body4 }: CustomTypographyProps) =>
  body4 &&
  css`
    font-size: ${themeGet('fontSizes.1')}px;
    font-weight: ${themeGet('fontWeights.0')};
    line-height: 171%;
  `

const body5Css = ({ body5 }: CustomTypographyProps) =>
  body5 &&
  css`
    font-size: ${themeGet('fontSizes.0')}px;
    font-weight: ${themeGet('fontWeights.0')};
    line-height: 171%;
  `

const header1Css = ({ header1 }: CustomTypographyProps) =>
  header1 &&
  css`
    font-size: 40px;
    font-weight: ${themeGet('fontWeights.3')};
    line-height: 140%;
    letter-spacing: -0.04em;
  `

const header2Css = ({ header2 }: CustomTypographyProps) =>
  header2 &&
  css`
    font-size: 34px;
    font-weight: ${themeGet('fontWeights.3')};
    line-height: 129%;
    letter-spacing: -0.04em;
  `

const header3Css = ({ header3 }: CustomTypographyProps) =>
  header3 &&
  css`
    font-size: 28px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 129%;
    letter-spacing: -0.02em;
  `

const header4Css = ({ header4 }: CustomTypographyProps) =>
  header4 &&
  css`
    font-size: ${themeGet('fontSizes.4')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 133%;
    letter-spacing: -0.02em;
  `

const header5Css = ({ header5 }: CustomTypographyProps) =>
  header5 &&
  css`
    font-size: ${themeGet('fontSizes.3')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 140%;
    letter-spacing: -0.01em;
  `

const header6Css = ({ header6 }: CustomTypographyProps) =>
  header6 &&
  css`
    font-size: 18px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 133%;
    letter-spacing: -0.01em;
  `

const caption1Css = ({ caption1 }: CustomTypographyProps) =>
  caption1 &&
  css`
    font-size: ${themeGet('fontSizes.2')}px;
    font-weight: ${themeGet('fontWeights.1')};
    line-height: 150%;
  `

const caption2Css = ({ caption2 }: CustomTypographyProps) =>
  caption2 &&
  css`
    font-size: ${themeGet('fontSizes.1')}px;
    font-weight: ${themeGet('fontWeights.1')};
    line-height: 143%;
  `

const caption3Css = ({ caption3 }: CustomTypographyProps) =>
  caption3 &&
  css`
    font-size: ${themeGet('fontSizes.0')}px;
    font-weight: ${themeGet('fontWeights.1')};
    line-height: 133%;
  `

const caption4Css = ({ caption4 }: CustomTypographyProps) =>
  caption4 &&
  css`
    font-size: 10px;
    font-weight: ${themeGet('fontWeights.1')};
    line-height: 120%;
  `

const subheader1Css = ({ subheader1 }: CustomTypographyProps) =>
  subheader1 &&
  css`
    font-size: ${themeGet('fontSizes.2')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 150%;
    letter-spacing: -0.012em;
  `

const subheader2Css = ({ subheader2 }: CustomTypographyProps) =>
  subheader2 &&
  css`
    font-size: ${themeGet('fontSizes.1')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 143%;
    letter-spacing: -0.012em;
  `

const subheader3Css = ({ subheader3 }: CustomTypographyProps) =>
  subheader3 &&
  css`
    font-size: ${themeGet('fontSizes.0')}px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 133%;
    letter-spacing: -0.012em;
  `

const subheader4Css = ({ subheader4 }: CustomTypographyProps) =>
  subheader4 &&
  css`
    font-size: 10px;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 120%;
    letter-spacing: -0.012em;
  `

interface MonospaceProps {
  monospace?: boolean
}

const monospaceCss = ({ monospace }: MonospaceProps) =>
  monospace &&
  css`
    font-feature-settings: 'tnum' on, 'lnum' on;
  `

interface ExtendedProps {
  center?: boolean
  ellipsis?: boolean
  left?: boolean
  lowercase?: boolean
  nowrap?: boolean
  preLine?: boolean
  preWrap?: boolean
  right?: boolean
  grow?: boolean
  noShrink?: boolean
}

const centerCss = ({ center }: ExtendedProps) =>
  center &&
  css`
    text-align: center;
  `

const ellipsisCss = ({ ellipsis }: ExtendedProps) =>
  ellipsis &&
  css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `

const leftCss = ({ left }: ExtendedProps) =>
  left &&
  css`
    text-align: left;
  `

const lowercaseCss = ({ lowercase }: ExtendedProps) =>
  lowercase &&
  css`
    text-transform: lowercase;
  `

const nowrapCss = ({ nowrap }: ExtendedProps) =>
  nowrap &&
  css`
    white-space: nowrap;
  `

const preLineCss = ({ preLine }: ExtendedProps) =>
  preLine &&
  css`
    white-space: pre-line;
  `

const preWrapCss = ({ preWrap }: ExtendedProps) =>
  preWrap &&
  css`
    white-space: pre-wrap;
  `

const rightCss = ({ right }: ExtendedProps) =>
  right &&
  css`
    text-align: right;
  `

const growCss = ({ grow }: ExtendedProps) =>
  grow &&
  css`
    flex-grow: 1;
  `

const noShrinkCss = ({ noShrink }: ExtendedProps) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

type Props = ColorProps &
  CustomColorProps &
  CustomTypographyProps &
  ExtendedProps &
  LayoutProps &
  MarginProps &
  MonospaceProps &
  TypographyProps

const Text = styled.p<Props>`
  margin: 0;
  padding: 0;
  position: relative;

  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2em;

  ${layout}
  ${margin}

  ${primaryCss}
  ${bodyCss}
  ${dangerCss}
  ${headingCss}
  ${importantCss}
  ${inverseCss}
  ${mutedCss}
  ${successCss}
  ${warningCss}

  ${action1Css}
  ${action2Css}
  ${action3Css}
  ${action4Css}
  ${action5Css}
  ${body1Css}
  ${body2Css}
  ${body3Css}
  ${body4Css}
  ${body5Css}
  ${caption1Css}
  ${caption2Css}
  ${caption3Css}
  ${caption4Css}
  ${header1Css}
  ${header2Css}
  ${header3Css}
  ${header4Css}
  ${header5Css}
  ${header6Css}
  ${subheader1Css}
  ${subheader2Css}
  ${subheader3Css}
  ${subheader4Css}
  ${monospaceCss}

  ${centerCss}
  ${ellipsisCss}
  ${leftCss}
  ${lowercaseCss}
  ${nowrapCss}
  ${preLineCss}
  ${preWrapCss}
  ${rightCss}
  ${growCss}
  ${noShrinkCss}


  ${color}
  ${typography}
`

interface BoldProps {
  bold?: boolean
  semiBold?: boolean
}

const boldCss = ({ bold }: BoldProps) =>
  bold &&
  css`
    font-weight: ${themeGet('fontWeights.2')};
  `

const semiBoldCss = ({ semiBold }: BoldProps) =>
  semiBold &&
  css`
    font-weight: ${themeGet('fontWeights.1')};
  `

type SpanProps = CustomColorProps &
  ColorProps &
  TypographyProps &
  BoldProps &
  MonospaceProps

export const Span = styled.span<SpanProps>`
  ${primaryCss}
  ${bodyCss}
  ${dangerCss}
  ${headingCss}
  ${importantCss}
  ${inverseCss}
  ${mutedCss}
  ${successCss}
  ${warningCss}
  ${boldCss}
  ${semiBoldCss}
  ${monospaceCss}

  ${color}
  ${typography}
`

export default Text
