import React, { useState } from 'react'

import { Menu, MenuItem } from '@mui/material'

import { Button } from 'Components/UI'

import { Language } from 'Constants/ids'

import { useAppContext } from 'Services/AppContext'
import { _ } from 'Services/I18n'
import { setLocale } from 'Services/Store/i18n'

function LanguageMenu() {
  const { locale } = useAppContext()

  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  const open = Boolean(anchor)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchor(null)
  }

  return (
    <>
      <Button
        mr={4}
        small
        variant="outlined"
        // @ts-ignore
        onClick={handleOpenMenu}
      >
        {_(`locale.${locale}`)}
      </Button>

      <Menu
        anchorEl={anchor}
        id="basic-menu"
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => setLocale(Language.Mn)}>
          {_(`locale.${Language.Mn}`)}
        </MenuItem>
        <MenuItem onClick={() => setLocale(Language.Ch)}>
          {_(`locale.${Language.Ch}`)}
        </MenuItem>
        <MenuItem onClick={() => setLocale(Language.Ru)}>
          {_(`locale.${Language.Ru}`)}
        </MenuItem>
        <MenuItem onClick={() => setLocale(Language.En)}>
          {_(`locale.${Language.En}`)}
        </MenuItem>
        <MenuItem onClick={() => setLocale(Language.Jp)}>
          {_(`locale.${Language.Jp}`)}
        </MenuItem>
      </Menu>
    </>
  )
}

export default LanguageMenu
