import { persistState } from '@storeon/localstorage'
import { createStoreon, StoreonStore } from 'storeon'

import { STORE } from 'Config'

import Shared from 'Services/Shared'

import {
  Events as CooLogsEvents,
  INITIAL_STATE as COO_LOGS_STATE,
  KEY as COO_LOGS_KEY,
  module as cooLogsModule,
  State as CooLogsState,
} from './cooLogs'
import {
  Events as I18nEvents,
  INITIAL_STATE as I18N_INITIAL_STATE,
  KEY as I18N_KEY,
  module as i18nModule,
  State as I18nState,
} from './i18n'
import {
  Events as PasswordResetStateEvents,
  INITIAL_STATE as PASSWORD_RESET_INITIAL_STATE,
  KEY as PASSWORD_RESET_STATE_KEY,
  module as passwordResetStateModule,
  State as PasswordResetStateState,
} from './passwordResetState'
import {
  Events as SessionEvents,
  INITIAL_STATE as SESSION_INITIAL_STATE,
  KEY as SESSION_KEY,
  module as sessionModule,
  State as SessionState,
} from './session'

export const STORE_CLEAR = 'clear'

interface MainEvents {
  clear: void
}

export type State = I18nState &
  SessionState &
  PasswordResetStateState &
  CooLogsState
export type Events = I18nEvents &
  SessionEvents &
  PasswordResetStateEvents &
  MainEvents &
  CooLogsEvents

type Store = StoreonStore<State, Events>

function createStore() {
  const modules = [
    sessionModule,
    passwordResetStateModule,
    i18nModule,
    cooLogsModule,
    persistState(
      [SESSION_KEY, PASSWORD_RESET_STATE_KEY, I18N_KEY, COO_LOGS_KEY],
      {
        key: STORE.KEY,
      },
    ),
  ]

  const store = createStoreon<State, Events>(modules)
  Shared.setStore(store)

  store.on(STORE_CLEAR, state => ({
    ...state,
    [I18N_KEY]: I18N_INITIAL_STATE,
    [SESSION_KEY]: SESSION_INITIAL_STATE,
    [PASSWORD_RESET_STATE_KEY]: PASSWORD_RESET_INITIAL_STATE,
    [COO_LOGS_KEY]: COO_LOGS_STATE,
  }))

  return store
}

export { createStore }
export type { Store }
