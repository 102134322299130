import { StoreonModule } from 'storeon'
import { SearchableOptionType } from 'Types/searchable'

const KEY = 'cooLogs'

type StateValues = {
  dateRange?: {
    from: string
    to: string
  }
  filters?: SearchableOptionType
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'cooLogs/update': StateValues
  'cooLogs/clear': void
}

export const INITIAL_STATE: StateValues = {
  filters: undefined,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))

  store.on('cooLogs/update', (state, values) => ({
    [KEY]: {
      ...state[KEY],
      ...values,
    },
  }))

  store.on('cooLogs/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

export { KEY, module }
export type { Events, State }
