import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const Content = styled.div`
  color: ${themeGet('colors.text.body')};
  text-align: center;
  font-size: ${themeGet('fontSizes.1')}px;
  font-weight: ${themeGet('fontWeights.2')};
  padding: ${themeGet('space.2')}px 0;
`
