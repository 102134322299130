import Envs from 'Services/Envs'

export const APP_ENV = Envs.get('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_STAGING = APP_ENV === 'staging'
export const IS_DEVELOPMENT = APP_ENV === 'development'

export const APP = {
  NAME: 'adb-web',
}

const API_SSL_ENABLED = Envs.get('API_SSL_ENABLED') === 'true'
const API_PROTOCOL = API_SSL_ENABLED ? 'https' : 'http'
const SOCKET_PROTOCOL = API_SSL_ENABLED ? 'wss' : 'ws'

export const WEB3 = {
  PROVIDER: parseInt(Envs.get('WEB3_PROVIDER'), 10),
  ALCHEMY_API_KEY: Envs.get('WEB3_ALCHEMY_API_KEY'),
  CONTRACT_ADDRESS: Envs.get('WEB3_CONTRACT_ADDRESS'),
  WALLET_ADDRESS: Envs.get('WEB3_WALLET_ADDRESS'),
  POLYGONSCAN_LINK_TX: IS_STAGING
    ? 'https://mumbai.polygonscan.com/tx/'
    : 'https://polygonscan.com/tx/',
  POLYGONSCAN_LINK_ADDRESS: IS_STAGING
    ? 'https://mumbai.polygonscan.com/address/'
    : 'https://polygonscan.com/address/',
}

export const API = {
  URL: `${API_PROTOCOL}://${Envs.get('API_URL')}`,
  WS: `${SOCKET_PROTOCOL}://${Envs.get('API_URL')}`,
}

export const STORE = {
  KEY: `${APP.NAME}/store`,
}

export const MAPS = {
  API_KEY: Envs.get('MAPS_API_KEY'),
}
