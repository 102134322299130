import invariant from 'tiny-invariant'

import forOwn from 'lodash/forOwn'
import isFunction from 'lodash/isFunction'

import GlobalStyle from './GlobalStyle'
import ThemeProvider from './Provider'
import { DEFAULT_VARIANT } from './types'

function createGetParam<TParams, TProps>(params: TParams) {
  return (paramName: keyof TParams) => (props: TProps) => {
    const variants: TParams[keyof TParams] | undefined = params?.[paramName]

    invariant(
      variants,
      `Variant "${paramName as string}" should be defined in params`,
    )

    let prop = DEFAULT_VARIANT

    // TODO: some magic here, need to test/check
    forOwn(props, (value, key) => {
      if (variants?.[key] && value) prop = key
    })

    return isFunction(variants[prop]) ? variants[prop](props) : variants[prop]
  }
}

export { createGetParam, GlobalStyle, ThemeProvider }
