import AdminSettings from './AdminSettings'
import CooPage from './CooPage'
import CooTable from './CooTable'
import Log from './Log'
import Root from './Root'
import SearchLogs from './SearchLogs'

export default {
  AdminSettings,
  CooPage,
  CooTable,
  Log,
  Root,
  SearchLogs,
}
