import React from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'

import { Column } from 'Components/UI'

import { UserFragment } from 'GraphQL/Main/TypedDocuments'

type Props = {
  route?: RouteConfig
  user?: UserFragment
}

function RootLayout({ route, user }: Props) {
  return (
    <Column fullHeight fullWidth>
      {renderRoutes(route?.routes, { user })}
    </Column>
  )
}

export default RootLayout
