import { ApolloCache } from '@apollo/client'

import {
  SystemSettingsDocument,
  SystemSettingsFragment,
} from 'GraphQL/Main/TypedDocuments'

type UpdateSystemSettingsProps = {
  cache: ApolloCache<any>
  systemSettings?: SystemSettingsFragment
}

function update({ cache, systemSettings }: UpdateSystemSettingsProps) {
  const query = {
    query: SystemSettingsDocument,
  }

  const data = cache.readQuery(query)

  cache.writeQuery({
    ...query,
    data: {
      ...data,
      systemSettings,
    },
  })
}

const SystemSettingsUpdater = {
  update,
}
export default SystemSettingsUpdater
