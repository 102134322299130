import styled, { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const Table = styled.table`
  width: 100%;

  td {
    padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  }

  tr > td:first-child {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.primary500')};
    width: 25%;

    ${({ theme }) => theme.styledBreakpoints.down('medium')} {
      width: 125px;
    }
  }

  tr > td:nth-child(2) {
    color: ${themeGet('colors.primary500')};
    background-color: ${themeGet('colors.neutral700')};
  }
`

type ValidationRowProps = { valid?: boolean; checking?: boolean }

export const checkingCss = ({ checking }: ValidationRowProps) =>
  checking &&
  css`
    background-color: ${themeGet('colors.primary500')};
  `

export const ValidationRow = styled.td`
  height: 45px;
  word-break: break-all;

  ${checkingCss}
`

export const StyledSearchableStatusRow = styled.td``
