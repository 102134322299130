import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { AdminLayout } from 'Containers/Layout'
import Admin from 'Containers/Pages/Admin'
import NotFound from 'Containers/Pages/NotFound'

function adminRoutes(): RouteConfig {
  return {
    path: paths.ROOT,
    component: AdminLayout,
    routes: [
      {
        path: paths.ROOT,
        exact: true,
        component: Admin.Root,
      },
      {
        path: paths.SETTINGS,
        exact: true,
        component: Admin.AdminSettings,
      },
      {
        path: paths.SEARCH_LOGS,
        exact: true,
        component: Admin.SearchLogs,
      },
      { path: paths.LOG, exact: true, component: Admin.Log },
      {
        path: paths.COO_ROOT(),
        exact: true,
        component: Admin.CooPage,
      },
      {
        path: paths.COO_VERSION(),
        exact: true,
        component: Admin.CooPage,
      },

      { component: NotFound },
    ],
  }
}

export default adminRoutes
