import React from 'react'
import { toast as toastify, ToastOptions } from 'react-toastify'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import { Toast as ToastComponent } from 'Components/UI'

interface Params {
  label?: React.ReactNode
  text: React.ReactNode
  icon?: React.ReactNode
  footer?: React.ReactNode
}

const DEFAULT_OPTIONS: ToastOptions = {
  autoClose: 2500,
  closeOnClick: true,
  pauseOnHover: true,
}

const toast = {
  info: (params: Params, options: ToastOptions = DEFAULT_OPTIONS) => {
    toastify.info(
      <ToastComponent
        {...params}
        icon={params?.icon ? params.icon : <InfoOutlinedIcon />}
        info
        label={params?.label ? params.label : 'Info'}
      />,
      options,
    )
  },

  warning: (params: Params, options: ToastOptions = DEFAULT_OPTIONS) => {
    toastify.warning(
      <ToastComponent
        {...params}
        icon={params?.icon ? params.icon : <WarningAmberOutlinedIcon />}
        label={params?.label ? params.label : 'Warning'}
        warning
      />,
      options,
    )
  },

  error: (
    { label = 'Error', ...rest }: Params,
    options: ToastOptions = DEFAULT_OPTIONS,
  ) => {
    toastify.error(
      <ToastComponent
        {...rest}
        danger
        icon={rest?.icon ? rest.icon : <ErrorOutlineOutlinedIcon />}
        label={label}
      />,
      options,
    )
  },

  success: (params: Params, options: ToastOptions = DEFAULT_OPTIONS) => {
    toastify.success(
      <ToastComponent
        {...params}
        icon={params?.icon ? params.icon : <CheckCircleOutlineIcon />}
        label={params?.label ? params.label : 'Success'}
        success
      />,
      options,
    )
  },
}

export default toast
