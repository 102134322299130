import React, { useCallback } from 'react'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ApolloProvider } from '@apollo/client'
import { createBrowserHistory } from 'history'
import { StoreContext } from 'storeon/react'

import { Toast } from 'Components/UI'

import { createApolloClient } from 'Services/Apollo'
import { initTranslations } from 'Services/I18n'
import { createStore } from 'Services/Store'

import { GlobalStyle, ThemeProvider } from 'Theme'

import App from './App'

import 'react-toastify/dist/ReactToastify.min.css'
import 'react-vis/dist/style.css'

const history = createBrowserHistory()
const apolloClient = createApolloClient()
const store = createStore()

initTranslations({ store })

function Container() {
  const renderToastCloseButton = useCallback(
    ({ closeToast }) => <Toast.CloseButton onClose={closeToast} />,
    [],
  )
  return (
    <ApolloProvider client={apolloClient}>
      <StoreContext.Provider value={store}>
        <ThemeProvider>
          <GlobalStyle />

          <ToastContainer
            className="toast-container_v2"
            closeButton={renderToastCloseButton}
            closeOnClick={false}
            draggable
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss
            pauseOnHover
            position="top-right"
            rtl={false}
          />

          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </StoreContext.Provider>
    </ApolloProvider>
  )
}

export default Container
