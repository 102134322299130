import { RefObject, useEffect } from 'react'

type HandlerFunction = (event: MouseEvent | TouchEvent) => void

function useOnClickOutside(
  ref?: RefObject<HTMLElement>,
  handler?: HandlerFunction,
  omitRef?: RefObject<HTMLElement>,
) {
  useEffect(() => {
    const listener: HandlerFunction = event => {
      if (
        !ref?.current ||
        ref?.current.contains(event.target as Node) ||
        (omitRef &&
          omitRef.current &&
          omitRef.current.contains(event.target as Node))
      ) {
        return
      }
      handler?.(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [omitRef, ref, handler])
}

export default useOnClickOutside
