import React from 'react'

import { MarginProps } from 'styled-system'

import isArray from 'lodash/isArray'

import { StyledCaption } from './styles'

type Props = MarginProps & {
  caption?: string
  success?: boolean
  danger?: boolean
}

function Caption({ caption, success, danger, ...rest }: Props) {
  if (!caption) return null

  if (success) {
    return (
      <StyledCaption {...rest} success>
        {caption}
      </StyledCaption>
    )
  }

  if (danger) {
    return isArray(caption) ? (
      <>
        {caption.map((text, index) => (
          <StyledCaption {...rest} danger key={`${text}+${index + 1}`}>
            {text}
          </StyledCaption>
        ))}
      </>
    ) : (
      <StyledCaption {...rest} danger>
        {caption}
      </StyledCaption>
    )
  }

  return <StyledCaption {...rest}>{caption}</StyledCaption>
}

export default Caption
