import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  background-color: ${themeGet('colors.white')};
  border-radius: 5px;
  box-shadow: 0 0 ${themeGet('space.3')}px ${themeGet('colors.divider.default')};
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${themeGet('space.3')}px;

  list-style: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li``
