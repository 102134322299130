import styled, { css } from 'styled-components'

import { DataGrid as MaterialDataGrid } from '@mui/x-data-grid'

import { DataGridProps } from './types'

const dataGridIsClickableCss = ({ isClickable }: DataGridProps) =>
  isClickable && css``

export const StyledGrid = styled(MaterialDataGrid)<DataGridProps>`
  &.MuiDataGrid-root {
    border: none;
    overflow: hidden;

    & .MuiDataGrid-row {
      cursor: pointer;
    }

    & .MuiDataGrid-cell {
      &:focus {
        outline: none;
      }
    }

    & .MuiDataGrid-cell:first-child {
      color: #ffffff;
      background-color: #1f3c92;
    }

    & .MuiDataGrid-columnHeaders {
      color: #ffffff;
      background-color: #1f3c92;

      &:focus,
      &:focus-within {
        outline: none;
      }
    }

    ${dataGridIsClickableCss}
  }
`
