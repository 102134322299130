import styled, { css } from 'styled-components'
import { margin, MarginProps, width, WidthProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

type ProgressProps = { value: number }
type ContainerProps = MarginProps &
  WidthProps & {
    transparent?: boolean
  }

const containerTransparentCss = ({ transparent }: ContainerProps) =>
  transparent &&
  css`
    background-color: transparent;
    box-shadow: none;
  `

export const Container = styled.div<ContainerProps>`
  padding: ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.white')};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  ${margin}
  ${width}

  ${containerTransparentCss}
`

export const Progress = styled.div<ProgressProps>`
  display: flex;

  background-image: linear-gradient(
    135deg,
    ${themeGet('colors.primary500')} 25%,
    ${themeGet('colors.primary500')} 50%,
    ${themeGet('colors.primary500')} 75%,
    ${themeGet('colors.primary500')} 100%
  );
  background-size: 20px 20px;

  animation: barberpole 4s linear infinite;

  @keyframes barberpole {
    100% {
      background-position: 200px 0;
    }
  }

  flex-shrink: 0;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;

  :after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: ${themeGet('colors.background.block')};
    width: ${props => 100 - props.value}%;
  }
`
