import React from 'react'
import { renderRoutes } from 'react-router-config'

import { routes } from 'Config/Routes'

import { useAppContext } from 'Services/AppContext'

import { Container } from './styles'

function AppContainer() {
  const { me } = useAppContext()

  return <Container>{renderRoutes(routes(me))}</Container>
}

export default AppContainer
