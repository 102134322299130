import { LOGIN } from 'Constants/paths'

import { MeDocument } from 'GraphQL/Main/TypedDocuments'

import shared from 'Services/Shared'
import { PasswordResetState } from 'Services/Store/passwordResetState'
import { Session } from 'Services/Store/session'

import { STORE_CLEAR } from './Store'

async function signIn({ accessToken }: { accessToken?: string }) {
  const client = shared.getApolloClient()

  Session.setToken(accessToken)

  const result = await client.query({ query: MeDocument })

  Session.setUserId(result.data.me.id)

  if (!PasswordResetState.getEmail()) {
    return
  }
  PasswordResetState.clear()
}

async function signOut() {
  const store = shared.getStore()
  store.dispatch(STORE_CLEAR)

  const client = shared.getApolloClient()
  client.stop()
  await client.resetStore()

  // TODO: disconnect subscription links and remove their tokens

  window.location.replace(LOGIN)
}

export default {
  signOut,
  signIn,
}
