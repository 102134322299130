import React from 'react'

import { MarginProps } from 'styled-system'

import CircularProgress from '@mui/material/CircularProgress'

import { Column } from 'Components/UI/Flex'

type Props = MarginProps & {
  size?: number
  fullScreen?: boolean
  inverse?: boolean
}

function Loader({ size = 24, fullScreen, inverse, ...rest }: Props) {
  if (fullScreen) {
    return (
      <Column
        center
        color={inverse ? 'white' : 'primary500'}
        fullScreen
        justifyCenter
      >
        <CircularProgress color="inherit" size={size} />
      </Column>
    )
  }

  return (
    <Column {...rest} color={inverse ? 'white' : 'primary500'}>
      <CircularProgress color="inherit" size={size} />
    </Column>
  )
}

export default Loader
