import React, { useCallback, useMemo, useState } from 'react'
import {
  ActiveModifiers,
  CaptionLayout,
  DateRange,
  DayPickerRangeProps,
} from 'react-day-picker'

import { PaddingProps } from 'styled-system'

import { LinkButton } from 'Components/UI/Buttons'
import { Row } from 'Components/UI/Flex'

import { Container, SizeProps, StyleRangePicker } from './styles'
import { isSelectingFirstDay } from './utils'

type Props = Partial<DayPickerRangeProps> &
  PaddingProps &
  SizeProps & {
    captionLayout?: CaptionLayout
    value?: DateRange
    withToday?: boolean
    withClear?: boolean
    onChange?: (pickedDates?: { from: Date; to: Date }) => void
  }

function DateRangePicker({
  value,
  withToday,
  withClear,
  fixedWeeks = true,
  captionLayout,
  onChange,
  ...rest
}: Props) {
  const [from, setFrom] = useState<Date | undefined>(value?.from)
  const [to, setTo] = useState<Date | undefined>(value?.to)
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(value?.to)
  const [month, setMonth] = useState<Date>(
    value?.from ?? value?.to ?? new Date(),
  )

  const handleDayClick = useCallback(
    (day: Date, modifiers: ActiveModifiers) => {
      if (modifiers.disabled || !day) {
        return
      }

      if (isSelectingFirstDay(day, from, to)) {
        setFrom(day)
        setTo(undefined)
        setEnteredTo(undefined)
      } else {
        setFrom(from)
        setTo(day)
        setEnteredTo(day)

        if (from) {
          onChange?.({ from, to: day })
        }
      }
    },
    [from, to, onChange, setFrom, setTo, setEnteredTo],
  )

  const handleTodayClick = useCallback(() => {
    setMonth(new Date())
  }, [])

  const isMonthEqualCurrentMonth = useMemo(() => {
    const now = new Date()
    return (
      month?.getFullYear?.() === now.getFullYear() &&
      month?.getMonth?.() === now.getMonth()
    )
  }, [month])

  const handleDayEnter = useCallback(
    (day: Date) => {
      if (isSelectingFirstDay(day, from, to)) {
        return
      }

      setEnteredTo(day)
    },
    [from, to],
  )

  const handleClear = useCallback(() => {
    setFrom(undefined)
    setTo(undefined)
    onChange?.(undefined)
  }, [onChange])

  const modifiers = useMemo(
    () => ({
      start: from ?? false,
      end: enteredTo ?? false,
    }),
    [from, enteredTo],
  )

  return (
    <Container>
      <StyleRangePicker
        {...rest}
        captionLayout={captionLayout}
        fixedWeeks={fixedWeeks}
        mode="range"
        modifiers={modifiers}
        month={month}
        selected={{ from, to: enteredTo }}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayEnter}
        onMonthChange={setMonth}
      />
      {(withToday || withClear) && (
        <Row
          justifyContent={withClear ? 'space-between' : 'flex-end'}
          mb={4}
          mx={5}
        >
          {withClear && (
            <LinkButton disabled={!from && !to} onClick={handleClear}>
              CLEAR
            </LinkButton>
          )}

          {withToday && (
            <LinkButton
              disabled={isMonthEqualCurrentMonth}
              onClick={handleTodayClick}
            >
              SHOW TODAY
            </LinkButton>
          )}
        </Row>
      )}
    </Container>
  )
}

export default DateRangePicker
