import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import { useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import ShowIcon from '@mui/icons-material/Visibility'
import HideIcon from '@mui/icons-material/VisibilityOff'
import { GridColDef } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { CooColumns, CooColumnsType } from 'Types/coos'
import { SearchableOptionType } from 'Types/searchable'

import { debounce } from 'lodash'

import { DataGrid, Header, Stamp, TableContainer } from 'Components/Blocks'
import {
  Column,
  DateRangeInput,
  IconButton,
  Input,
  Row,
  Select,
  Text,
} from 'Components/UI'

import { WEB3 } from 'Config'

import {
  COO_ROOT,
  COO_VERSION,
  ROOT,
  SEARCH_LOGS,
  SETTINGS,
} from 'Constants/paths'

import { CooListDocument, ShortCooFragment } from 'GraphQL/Main/TypedDocuments'

import { useCOO, useCOOLogsState, useResponsiveLayout } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { StyledLink } from './styles'
import { processCoosData } from './utils'

const SEARCH_DEBOUNCE = 350

function Log() {
  const s = useScopedI18n('coo')
  const history = useHistory()
  const { isWide } = useResponsiveLayout()
  const { updateCOO } = useCOO()
  const { state, updateDateRange, updateFilter } = useCOOLogsState()

  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [referenceNumber, setReferenceNumber] = useState('')
  const [blankNumber, setBlankNumber] = useState('')
  const [activeInputIndex, setActiveInputIndex] = useState<Number | null>(null)
  const [isSearchableIcon, setIsSearchableIcon] = useState({})

  const {
    data,
    loading,
    refetch: cooLogsRefetch,
  } = useQuery(CooListDocument, {
    variables: {
      page,
      search: blankNumber !== '' ? blankNumber : referenceNumber,
      ...(state.dateRange?.from && { from: state.dateRange?.from }),
      ...(state.dateRange?.to && { to: state.dateRange?.to }),
      isSearchable: state.filters?.value,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (loading) {
      return
    }
    setTotalPages(data?.cooList.pages || 0)
  }, [loading, data])

  const coos = useMemo(() => processCoosData(data?.cooList.rows), [data])

  const debouncedSearch = useMemo(() => {
    return debounce(
      (value: string, callback: (value: string) => void) => callback?.(value),
      SEARCH_DEBOUNCE,
    )
  }, [])

  const options = useMemo(
    () => [
      { label: 'All', value: undefined },
      { label: 'Searchable', value: true },
      { label: 'Hidden', value: false },
    ],
    [],
  )

  const handleChangePage = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onChangePage']>
  >(page => {
    setPage(page)
  }, [])

  const handleBlankNumberChange = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(
    event => {
      if (referenceNumber) setReferenceNumber('')

      let searchKey = event.target.value.toUpperCase()

      if (searchKey.indexOf(' ') === -1) {
        searchKey = `${searchKey.slice(0, 1)} ${searchKey.slice(1)}`
      }

      debouncedSearch(searchKey, setBlankNumber)
      setPage(0)
    },
    [debouncedSearch, referenceNumber],
  )

  const handleReferenceNumberChange = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(
    event => {
      if (blankNumber) setBlankNumber('')

      let searchKey = event.target.value

      if (searchKey.indexOf(' ') === -1) {
        searchKey = `${searchKey.slice(0, 1)} ${searchKey.slice(1)}`
      }

      debouncedSearch(searchKey, setReferenceNumber)
      setPage(0)
    },
    [debouncedSearch, blankNumber],
  )

  const handleClickRow = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onClickRow']>
  >(
    row => {
      history.push(COO_ROOT(row.id))
    },
    [history],
  )

  const handleSwitchVisibility = useCallback<
    NonNullable<ComponentProps<typeof IconButton>['onClick']>
  >(
    async (event, rowId, internalId, value) => {
      event.preventDefault()
      event.stopPropagation()

      setIsSearchableIcon(prevState => ({
        ...prevState,
        [rowId]: !prevState[rowId],
      }))

      await updateCOO.mutate({
        internalId,
        isSearchable: !value,
      })

      cooLogsRefetch().then()
    },
    [updateCOO, cooLogsRefetch],
  )

  const handleOpenLink = useCallback((event, url: string) => {
    event.stopPropagation()

    window.open(url, '_blank')
  }, [])

  const handleChangeDate = useCallback(
    (newDate?: { from: DateTime; to: DateTime }) => {
      if (!newDate) {
        updateDateRange(undefined)
        return
      }

      const fromISO = newDate && newDate.from.startOf('day').toUTC(0).toISO()
      const toISO = newDate && newDate.to.endOf('day').toUTC(0).toISO()

      if (!fromISO || !toISO) return

      setPage(0)

      updateDateRange({ from: fromISO, to: toISO })
    },
    [updateDateRange],
  )

  const handleSearchableStatusFilter = useCallback(
    (_, option?: SearchableOptionType) => {
      updateFilter(option)
    },
    [updateFilter],
  )

  const columns: GridColDef<CooColumnsType>[] = useMemo(
    () => [
      {
        field: CooColumns.ID,
        renderHeader: () => <Text subheader2>{s('columnName.coo')}</Text>,
        renderCell: row => <Text>{row.value}</Text> || 'N/A',
        sortable: false,
        flex: 0.5,
        minWidth: 50,
        align: 'center',
      },
      {
        field: CooColumns.Refnumber,
        renderHeader: () => <Text subheader2>{s('columnName.refNo')}</Text>,
        renderCell: row => <Text>{row.value}</Text> || 'N/A',
        sortable: false,
        flex: 0.5,
        minWidth: 65,
      },
      {
        field: CooColumns.BlankNumber,
        renderHeader: () => (
          <Text subheader2>{s('columnName.blankNumber')}</Text>
        ),
        renderCell: row => <Text preWrap>{row.value}</Text> || 'N/A',
        sortable: false,
        flex: 0.4,
        minWidth: 90,
      },
      {
        field: CooColumns.CreatedAt,
        renderHeader: () => (
          <Text preWrap subheader2>
            {s('columnName.storeDBDate')}
          </Text>
        ),
        renderCell: row => <Text preWrap>{row.value}</Text> || 'N/A',
        sortable: false,
        flex: 1,
        minWidth: 80,
      },
      {
        field: CooColumns.ExecutedAt,
        renderHeader: () => (
          <Text preWrap subheader2>
            {s('columnName.storeBCDate')}
          </Text>
        ),
        renderCell: row => <Text preWrap>{row.value}</Text> || 'N/A',
        sortable: false,
        flex: 1,
        minWidth: 100,
      },
      {
        field: CooColumns.Footprint,
        renderHeader: () => <Text subheader2>{s('columnName.footprint')}</Text>,
        renderCell: row => <Text>{row.value}</Text> || 'N/A',
        sortable: false,
        flex: 1.7,
        minWidth: 270,
      },
      {
        field: CooColumns.TransactionHash,
        renderHeader: () => <Text subheader2>{s('columnName.transId')}</Text>,
        renderCell: row =>
          row.value ? (
            <StyledLink
              onClick={event =>
                handleOpenLink(event, `${WEB3.POLYGONSCAN_LINK_TX}${row.value}`)
              }
            >
              {s('polygon')}
            </StyledLink>
          ) : (
            'N/A'
          ),
        sortable: false,
        flex: 0.5,
        minWidth: 70,
      },
      {
        field: CooColumns.Versions,
        renderHeader: () => <Text subheader2>{s('columnName.versions')}</Text>,
        renderCell: row =>
          row.value ? (
            <Column>
              {row.value?.map((item: ShortCooFragment) => {
                const {
                  version,
                  footprint,
                  data: { refnumber, blanknumber, id },
                } = item
                return (
                  <StyledLink
                    key={footprint}
                    onClick={_ =>
                      handleOpenLink(_, `${COO_VERSION(id, version)}`)
                    }
                  >
                    {refnumber} / {blanknumber} (v{version})
                  </StyledLink>
                )
              })}
            </Column>
          ) : (
            'N/A'
          ),
        sortable: false,
        minWidth: 150,
        flex: 0.6,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: CooColumns.IsSearchable,
        renderHeader: () => (
          <Text preWrap subheader2>
            {s('columnName.isSearchable')}
          </Text>
        ),
        renderCell: row => (
          <IconButton
            onClick={event => {
              handleSwitchVisibility(
                event,
                row.id,
                row.row.internalId,
                row.row.isSearchable,
              )
            }}
          >
            {isSearchableIcon[row.id] ?? !row.row.isSearchable ? (
              <HideIcon />
            ) : (
              <ShowIcon color="primary" />
            )}
          </IconButton>
        ),
        sortable: false,
        minWidth: 100,
        flex: 0.3,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [s, handleOpenLink, isSearchableIcon, handleSwitchVisibility],
  )

  const pageSize = data?.cooList.rows.length
  const totalEntries = data?.cooList.count

  return (
    <Column fullHeight>
      <Header
        goHomePath={ROOT}
        goSearchLogsPath={SEARCH_LOGS}
        goSettingsPath={SETTINGS}
        isHomeButtonShown
        isSearchLogsButtonShown
        isSettingsButtonShown
      />

      <Column center fullHeight my={8}>
        <TableContainer>
          <Stamp />

          <Row
            flexDirection={isWide ? 'row' : 'column'}
            fullWidth
            gap={6}
            pr={isWide ? 0 : 4}
          >
            <Input
              label={s('referenceNumber')}
              placeholder={s('enterReferenceNumber')}
              renderBeforeElement={() => <SearchIcon />}
              value={!activeInputIndex ? '' : undefined}
              width={1}
              onChange={handleReferenceNumberChange}
              onKeyDown={() => setActiveInputIndex(1)}
            />

            <Input
              label={s('blankNumber')}
              placeholder={s('enterBlankNumber')}
              renderBeforeElement={() => <SearchIcon />}
              value={activeInputIndex ? '' : undefined}
              width={1}
              onChange={handleBlankNumberChange}
              onKeyDown={() => setActiveInputIndex(0)}
            />

            <Row gap={6}>
              <Select
                label={s('searchableStatus')}
                options={options}
                value={state.filters ? state.filters : undefined}
                width={isWide ? 350 : 1}
                onChange={(_, option) =>
                  handleSearchableStatusFilter(_, option)
                }
              />

              <Row width={1}>
                <DateRangeInput
                  from={state.dateRange?.from}
                  to={state.dateRange?.to}
                  withClear
                  withToday
                  onChange={handleChangeDate}
                />
              </Row>
            </Row>
          </Row>

          <DataGrid
            columns={columns}
            getRowId={row => row.footprint}
            loading={loading}
            page={page}
            pageSize={pageSize}
            rows={coos}
            totalEntries={totalEntries}
            totalPages={totalPages}
            onChangePage={handleChangePage}
            onClickRow={handleClickRow}
          />
        </TableContainer>
      </Column>
    </Column>
  )
}

export default Log
