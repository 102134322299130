import React from 'react'

import ClearIcon from '@mui/icons-material/Clear'

import { IconButton } from 'Components/UI/Buttons'

interface Props {
  disabled?: boolean
  onClick?: () => void
}

function CloseModalButton({ disabled, onClick }: Props) {
  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <ClearIcon />
    </IconButton>
  )
}

export default CloseModalButton
