import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const DateRangeButton = styled.div`
  border: 1px solid ${themeGet('colors.neutral600')};
  border-radius: 3px;
  height: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: 0 ${themeGet('space.6')}px;
  gap: ${themeGet('space.3')}px;

  color: ${themeGet('colors.text.body')};

  &:hover {
    color: ${themeGet('colors.primary300')};
    border-color: ${themeGet('colors.primary300')};
  }
`
