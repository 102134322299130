import React from 'react'

import ShowIcon from '@mui/icons-material/Visibility'
import HideIcon from '@mui/icons-material/VisibilityOff'

import { IconButton, Row, Text } from 'Components/UI'

import { StyledSearchableStatusRow } from './styles'

type Props = {
  id: string
  isSearchable: boolean
  onClick: (id: string, value?: boolean) => void
}

function SearchableStatusRow({ id, isSearchable, onClick }: Props) {
  return (
    <StyledSearchableStatusRow>
      <Row center spaceBetween>
        <Text fontWeight={3}>{isSearchable ? 'Searchable' : 'Hidden'}</Text>
        <IconButton
          overflow="hidden"
          py={0}
          onClick={() => onClick(id, isSearchable)}
        >
          {isSearchable ? <ShowIcon color="primary" /> : <HideIcon />}
        </IconButton>
      </Row>
    </StyledSearchableStatusRow>
  )
}

export default SearchableStatusRow
