import compact from 'lodash/compact'

import { CooDataItemFragment } from 'GraphQL/Main/TypedDocuments'

function getItemsNetWeight(netWeight?: CooDataItemFragment | null | undefined) {
  const cWeight = netWeight?.c_weigth || ''
  const guWeight = netWeight?.gu_weight || ''
  return compact([cWeight, guWeight]).join(' ')
}

function getItemsGrossWeight(grossWeight?: CooDataItemFragment | undefined) {
  const dWeight = grossWeight?.d_weigth || ''
  const guWeight = grossWeight?.gu_weight || ''
  return compact([dWeight, guWeight]).join(' ')
}

function getItemsQuantity(quantity?: CooDataItemFragment | undefined) {
  const quantityQty = quantity?.quantity_qty || ''
  const guPack1 = quantity?.gu_pack1 || ''
  return compact([quantityQty, guPack1]).join(' ')
}

export default { getItemsGrossWeight, getItemsNetWeight, getItemsQuantity }
