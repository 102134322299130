import styled from 'styled-components'
import { margin, MarginProps, padding, PaddingProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { Column } from 'Components/UI'

type CardProps = MarginProps & PaddingProps

export const Card = styled.div<CardProps>`
  width: 100%;
  max-width: 500px;
  background-color: ${themeGet('colors.background.block')};
  border-radius: 4px;

  ${margin};
  ${padding};
`

export const Content = styled(Column)`
  background-image: url('/mncci.jpg');
  background-size: cover;
`

export const StyledForm = styled.form`
  align-items: center;
  justify-items: center;
  flex-direction: column;
  width: 100%;
`

export const StampImage = styled.img`
  transform: rotate(-11deg);
  width: 150px;
  height: 80px;
  margin-left: 32px;
  opacity: 0.7;
`

export const Container = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 100px;
  flex-shrink: 0;
`
