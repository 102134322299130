export const ROOT = '/'

// Auth
export const AUTH = `${ROOT}auth`
export const LOGIN = `${AUTH}/login`
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`
export const CHANGE_EMAIL_PASSWORD = `${AUTH}/change-email-password`

// Main
export type ServiceAppointmentsRouteParams = {
  serviceAppointmentId?: string
  defectId?: string
}
export const SERVICE_APPOINTMENTS = `${ROOT}serviceAppointments`
export const SERVICE_APPOINTMENT_ROOT = (serviceAppointmentId?: string) =>
  `${SERVICE_APPOINTMENTS}/${serviceAppointmentId || ':serviceAppointmentId'}`
export const SERVICE_APPOINTMENT_ROOT_PDF = (
  serviceAppointmentId?: string,
  withoutHeader?: boolean,
) =>
  `${SERVICE_APPOINTMENT_ROOT(serviceAppointmentId)}/report.pdf${
    withoutHeader ? '?withoutHeader=true' : ''
  }`
export const DEFECT_ROOT = (serviceAppointmentId?: string, defectId?: string) =>
  `${SERVICE_APPOINTMENT_ROOT(serviceAppointmentId)}/defect/${
    defectId || ':defectId'
  }`
export const CREATE_DEFECT = (serviceAppointmentId?: string) =>
  `${SERVICE_APPOINTMENT_ROOT(serviceAppointmentId)}/create-defect`
export const INSPECTORS = `${ROOT}inspectors`
export const ADMINS = `${ROOT}admins`

export type CooRouteParams = {
  cooId: string
  version?: string
}

export const LOG = `${ADMINS}/log`
export const SETTINGS = `${ADMINS}/settings`
export const SEARCH_LOGS = `${ADMINS}/search-logs`

export const COO_ROOT = (cooId?: string) => `${LOG}/${cooId || ':cooId'}`
export const COO_VERSION = (cooId?: string, version?: string | number | null) =>
  `${LOG}/${cooId || ':cooId'}/${version || ':version'}`
