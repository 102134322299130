import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import Shared from 'Services/Shared'

import {
  createAuthLink,
  createErrorLink,
  createRequestLink,
  createRetryLink,
  FloodgateLink,
} from './Links'
import typePolicies from './TypePolicies'

function createApolloClient() {
  const cache = new InMemoryCache({ typePolicies })
  const floodgateLink = new FloodgateLink()

  const client = new ApolloClient({
    // TODO: add token refresh link
    link: ApolloLink.from([
      createErrorLink(),
      createRetryLink(),
      floodgateLink,
      createAuthLink(),
      createRequestLink(),
    ]),
    cache,
    connectToDevTools: true,
  })

  Shared.setFloodgateLink(floodgateLink)
  Shared.setApolloClient(client)

  return client
}

export { createApolloClient }
