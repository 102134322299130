import { StoreonModule } from 'storeon'

import isEqual from 'lodash/isEqual'

import { Language } from 'Constants/ids'

import { i18n } from 'Services/I18n'

import Shared from '../Shared'

export const KEY = 'i18n'

interface StateValues {
  locale?: Language
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'i18n/set': { locale: Language }
}

export const INITIAL_STATE = {
  locale: Language.En,
}

export const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({ [KEY]: INITIAL_STATE }))

  store.on('i18n/set', (state, variables) => {
    const nextI18n = { ...state[KEY], ...variables }

    if (isEqual(nextI18n, state[KEY])) return null

    i18n.locale = nextI18n.locale

    return { [KEY]: nextI18n }
  })
}

function getState() {
  return Shared.getStore().get()[KEY]
}

export function getLocale() {
  return getState().locale
}

export function setLocale(locale: Language) {
  if (locale === getLocale()) return

  Shared.getStore().dispatch('i18n/set', { locale })
}

export { type Events, type State }
