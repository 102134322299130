import React from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'

import { Column } from 'Components/UI'

type Props = {
  route?: RouteConfig
}

function AuthLayout({ route }: Props) {
  return <Column fullScreen>{renderRoutes(route?.routes)}</Column>
}

export default AuthLayout
