import { ApolloCache } from '@apollo/client'

import { CheckCooDocument, CooFragment } from 'GraphQL/Main/TypedDocuments'

type UpdateCOOProps = {
  cache: ApolloCache<any>
  checkCOO?: CooFragment
}

function update({ cache, checkCOO }: UpdateCOOProps) {
  const query = {
    query: CheckCooDocument,
  }

  const data = cache.readQuery(query)
  if (!data) {
    return
  }

  cache.writeQuery({
    ...query,
    data: { ...data, checkCOO },
  })
}

const COOUpdater = {
  update,
}

export default COOUpdater
