import { RetryLink } from '@apollo/client/link/retry'

function createRetryLink() {
  return new RetryLink({
    delay: {
      initial: 300,
      max: 15000,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: error => {
        console.log({ error })
        return !!error
      },
    },
  })
}

export default createRetryLink
