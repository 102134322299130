import styled from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'

export type WrapperProps = MarginProps & LayoutProps

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  ${margin}
  ${layout}
`
