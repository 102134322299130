function estimateZoom(distance) {
  if (distance > 2000) return 3
  if (distance > 1500) return 3.5
  if (distance > 1000) return 4.5
  if (distance > 500) return 5
  if (distance > 200) return 6
  if (distance > 100) return 7
  if (distance > 50) return 8
  if (distance > 30) return 9
  if (distance > 15) return 10
  if (distance > 10) return 11
  if (distance > 5) return 12
  if (distance > 2) return 13
  if (distance > 1) return 14
  if (distance > 0.5) return 15
  return 16 // Default zoom for very close points
}

function getDistance(lat1, lon1, lat2, lon2) {
  const R = 6371 // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lon2 - lon1) * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c

  return distance
}

function findCenterSpherical(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): [number, number] {
  // Convert latitude and longitude from degrees to radians
  const radLat1 = (lat1 * Math.PI) / 180
  const radLon1 = (lon1 * Math.PI) / 180
  const radLat2 = (lat2 * Math.PI) / 180
  const radLon2 = (lon2 * Math.PI) / 180

  // Differences in coordinates
  const dLon = radLon2 - radLon1

  // Trigonometry calculations
  const bx = Math.cos(radLat2) * Math.cos(dLon)
  const by = Math.cos(radLat2) * Math.sin(dLon)
  const lat3 = Math.atan2(
    Math.sin(radLat1) + Math.sin(radLat2),
    Math.sqrt((Math.cos(radLat1) + bx) * (Math.cos(radLat1) + bx) + by * by),
  )
  const lon3 = radLon1 + Math.atan2(by, Math.cos(radLat1) + bx)

  // Convert back to degrees
  return [(lat3 * 180) / Math.PI, (lon3 * 180) / Math.PI]
}

export { estimateZoom, findCenterSpherical, getDistance }
