export enum CooItemColumns {
  ProductName = 'productName',
  HsCode = 'hs_code',
  NetWeight = 'netWeight',
  GrossWeight = 'grossWeight',
  Quantity = 'quantity',
  MethodOfPacking = 'gu_pack',
  TotalPacked = 'pack',
}

export type CooItemColumnsType = {
  id: string
  productName: string | null | undefined
  hs_code: string | null | undefined
  netWeight: string | undefined
  grossWeight: string | undefined
  quantity: string | undefined
  gu_pack: string | null | undefined
  pack: string | null | undefined
}
