const palette = {
  white: '#ffffff',
  black: '#000000',

  dustyGray: '#979797',
  athensGray: '#e9ebf1',

  neutral100: '#1a1a1a',
  neutral200: '#262626',
  neutral300: '#333333',
  neutral400: '#4d4d4d',
  neutral500: '#7a7a7a',
  neutral600: '#b2b2b2',
  neutral700: '#e5e5e5',
  neutral800: '#f2f2f2',
  neutral900: '#f7f7f7',
  neutral500Translucent: 'rgba(122, 122, 122, 0.54)',
  neutral900Translucent: 'rgba(255, 255, 255, 0.20)',

  primary100: '#0b023b',
  primary200: '#2d1c82',
  primary300: '#0067d3',
  primary400: '#1f3c92cc',
  primary500: '#1f3c92',

  success100: '#072c14',
  success200: '#0e5827',
  success300: '#16833b',
  success400: '#1daf4e',
  success500: '#24db62',

  warning100: '#2f2304',
  warning200: '#5f4607',
  warning300: '#8e6a0b',
  warning400: '#be8d0e',
  warning500: '#edb012',
  warning600: '#FF9900',
  warning700: '#FFFF66',

  danger100: '#2d0606',
  danger200: '#5b0b0b',
  danger300: '#881111',
  danger400: '#b61616',
  danger500: '#e31c1c',
  danger700: 'rgba(255, 28, 28, 0.20)',

  info100: '#092a28',
  info200: '#125551',
  info300: '#1c7f79',
  info400: '#25aaa2',
  info500: '#2ed4ca',
}

export default palette
