import styled from 'styled-components'
import {
  flex,
  FlexProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  overflow,
  OverflowProps,
  padding,
  PaddingProps,
} from 'styled-system'
import themeGet from '@styled-system/theme-get'

type CardProps = FlexProps &
  OverflowProps &
  PaddingProps &
  MarginProps &
  LayoutProps

const Card = styled.div<CardProps>`
  background-color: ${themeGet('colors.background.block')};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  ${flex}
  ${overflow}
  ${padding}
  ${margin}
  ${layout}
`

export default Card
