export enum Language {
  Mn = 'mn',
  Ch = 'zh',
  En = 'en',
  Ru = 'ru',
  Jp = 'ja',
}

export enum Breakpoints {
  ExtraSmall = 480,
  Small = 640,
  Medium = 768,
  Large = 1024,
  ExtraLarge = 1200,
}
