import React, { forwardRef, ReactNode, Ref, useState } from 'react'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { InputAdornment } from '@mui/material'

import Caption from 'Components/UI/Forms/Caption'

import {
  Container,
  PasswordIconWrapper,
  StyledInput,
  Wrapper,
  WrapperProps,
} from './styles'

type Props = WrapperProps & {
  caption?: string
  required?: boolean
  label?: ReactNode
  placeholder?: string
  success?: boolean
  danger?: boolean
  disabled?: boolean
  isMulti?: boolean
  type?: React.HTMLProps<HTMLInputElement>['type']
  small?: boolean
  inverse?: boolean
  defaultValue?: string | number
  maxRows?: number
  rows?: number
  value?: string | number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
  renderAfterElement?: (disabled: boolean) => React.ReactNode
  renderBeforeElement?: () => React.ReactNode
}

function Input(
  {
    caption,
    required,
    label,
    placeholder,
    success,
    danger,
    disabled,
    isMulti,
    type,
    small,
    inverse,
    defaultValue,
    maxRows,
    rows,
    value,
    onChange,
    onKeyDown,
    onBlur,
    renderAfterElement,
    renderBeforeElement,
    ...rest
  }: Props,
  ref?: Ref<HTMLInputElement>,
) {
  const [shownPassword, setShownPassword] = useState(false)

  return (
    <Wrapper {...rest}>
      <Container>
        <StyledInput
          InputProps={{
            startAdornment: renderBeforeElement && (
              <InputAdornment position="start">
                {renderBeforeElement()}
              </InputAdornment>
            ),
            endAdornment: renderAfterElement && (
              <InputAdornment position="start">
                {renderAfterElement(!!disabled)}
              </InputAdornment>
            ),
          }}
          defaultValue={defaultValue}
          disabled={disabled}
          error={danger}
          inverse={inverse}
          label={label}
          maxRows={maxRows}
          multiline={isMulti}
          placeholder={placeholder}
          ref={ref}
          required={required}
          rows={rows}
          size={small ? 'small' : 'medium'}
          type={type === 'password' && shownPassword ? 'text' : type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />

        {type === 'password' && (
          <PasswordIconWrapper onClick={() => setShownPassword(!shownPassword)}>
            {shownPassword ? (
              <VisibilityOff color="action" />
            ) : (
              <Visibility color="action" />
            )}
          </PasswordIconWrapper>
        )}
      </Container>

      <Caption caption={caption} danger={danger} mt={2} success={success} />
    </Wrapper>
  )
}

export default forwardRef(Input)
