import { ShortCooFragment } from 'GraphQL/Main/TypedDocuments'

export enum CooColumns {
  ID = 'id',
  BlankNumber = 'blanknumber',
  Refnumber = 'refnumber',
  CreatedAt = 'createdAt',
  ExecutedAt = 'executedAt',
  Footprint = 'footprint',
  TransactionHash = 'transactionHash',
  IsSearchable = 'isSearchable',
  Versions = 'versions',
  InternalId = 'internalId',
}

export type CooColumnsType = {
  id: string
  blanknumber?: string | null
  createdAt: string | null
  executedAt?: string | null
  footprint: string
  refnumber?: string | null
  transactionHash?: string | null
  version?: number | null
  isSearchable?: boolean
  versions?: ShortCooFragment[] | null
  internalId: string
}
