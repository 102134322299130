import React, { ComponentProps, useCallback } from 'react'

import { GridRowParams } from '@mui/x-data-grid'
import { CooColumnsType } from 'Types/coos'

import { Card } from 'Components/UI'

import NoRowsOverlay from './NoRowsOverlay'
import Pagination from './Pagination'
import { StyledGrid } from './styles'
import { DataGridProps } from './types'

type Props = DataGridProps & {
  pageSize?: number
  page?: number
  totalEntries?: number
  totalPages?: number
  loading?: boolean
  onChangePage?: ComponentProps<typeof Pagination>['onChangePage']
  onClickRow?: (row: CooColumnsType) => void
}

function DataGrid({
  pageSize,
  page,
  totalEntries,
  totalPages,
  loading,
  onChangePage,
  onClickRow,
  ...rest
}: Props) {
  const renderPagination = useCallback(() => {
    return (
      <Pagination
        loading={loading}
        page={page}
        pageSize={pageSize}
        totalEntries={totalEntries}
        totalPages={totalPages}
        onChangePage={onChangePage}
      />
    )
  }, [loading, page, pageSize, totalEntries, totalPages, onChangePage])

  const handleClickRow = useCallback(
    ({ row }: GridRowParams<CooColumnsType>) => {
      onClickRow?.(row)
    },
    [onClickRow],
  )

  return (
    <Card flex={1} overflow="auto">
      <StyledGrid
        {...rest}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        loading={loading}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          pagination: renderPagination,
        }}
        sortingMode="server"
        onRowClick={handleClickRow}
      />
    </Card>
  )
}

export default DataGrid
