import React from 'react'

import { Column, Text } from 'Components/UI'

function NoRowsOverlay() {
  return (
    <Column center fullHeight justifyCenter>
      <Text action2>No data yet</Text>
    </Column>
  )
}

export default NoRowsOverlay
