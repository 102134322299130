export enum SearchLogsColumn {
  ID = 'id',
  CreatedAt = 'createdAt',
  Found = 'found',
  Query = 'query',
}

export type ColumnsType = {
  id: string
  createdAt: string | null
  found?: number | string | boolean
  query?: string | null | boolean
}
