import { onError } from '@apollo/client/link/error'

import forEach from 'lodash/forEach'

export default function createErrorLink() {
  return onError(({ response, graphQLErrors, networkError, operation }) => {
    if (networkError)
      console.error(
        `[Network error]: ${networkError} ${JSON.stringify({
          response,
          name: operation.operationName,
          variables: operation.variables,
          graphQLErrors,
        })}`,
      )

    if (graphQLErrors) {
      forEach(graphQLErrors, ({ message, path, ...rest }) => {
        if (message === 'PersistedQueryNotFound') return

        console.log(
          `[GraphQL error]: Operation: ${
            operation?.operationName
          } Message: ${message}, Path: ${path} Info: ${JSON.stringify(rest)}`,
        )
      })

      // forward(operation)
    }

    if (operation.operationName === 'Me' && response) {
      response.errors = undefined
    }
  })
}
