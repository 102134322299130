import React from 'react'
import ReactModal from 'react-modal'

interface Props extends ReactModal.Props {
  className?: string
  modalClassName?: ReactModal.Classes | string
}

function Adapter({ className, modalClassName, ...props }: Props) {
  return (
    <ReactModal
      {...props}
      className={modalClassName}
      portalClassName={className}
    />
  )
}

export default Adapter
