import { ApolloLink, Observable } from '@apollo/client'
import { print } from 'graphql'
import { createClient } from 'graphql-ws'

import { Session } from 'Services/Store/session'

const KEEP_ALIVE_MS = 15_000

class WebSocketLink extends ApolloLink {
  private client
  private timedOut?: ReturnType<typeof setTimeout>
  private socket: WebSocket | null = null
  private token?: string

  constructor(url: string) {
    super()

    this.client = createClient({
      url,
      keepAlive: KEEP_ALIVE_MS,
      on: {
        ping: received => {
          if (!received) {
            this.timedOut = setTimeout(() => {
              if (this.socket?.readyState === WebSocket.OPEN)
                this.socket?.close(4408, 'Request Timeout')
            }, KEEP_ALIVE_MS / 2)
          }
        },
        pong: received => {
          if (received) {
            clearTimeout(this.timedOut)
          }
        },
        // @ts-ignore
        connected: (socket: WebSocket) => {
          this.socket = socket
        },
        closed: () => {
          this.socket = null
        },
      },
      connectionParams: () => {
        return {
          Authorization: `Bearer ${Session.getToken()}`,
        }
      },
      shouldRetry: () => true,
    })
  }

  // @ts-ignore
  request(operation) {
    return new Observable(sink =>
      this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: err => {
            if (err instanceof Error) {
              sink.error(err)
            } else if (err instanceof CloseEvent) {
              sink.error(
                new Error(
                  `Socket closed with event ${err.code}: ${err.reason || ''}`,
                ),
              )
            } else if (err instanceof Event) {
              sink.error(err)
            } else {
              sink.error(
                // @ts-ignore
                new Error(err.map(({ message }) => message).join(', ')),
              )
            }
          },
        },
      ),
    )
  }

  restart() {
    this.socket?.close(4205, 'Client Restart')
  }
}

export default WebSocketLink
