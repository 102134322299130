import flatMapDeep from 'lodash/flatMapDeep'
import values from 'lodash/values'

import { ERRORS_TEXT } from 'Constants/errors'

function getMessages(error: any) {
  if (error.message) return [error.message.replace('GraphQL error:', '').trim()]
  return flatMapDeep(error?.graphQLErrors, ({ state }) => values(state))
}

function getGraphQLErrors(error: any) {
  const messages = getMessages(error)
  return messages?.map(message => ERRORS_TEXT[message] ?? message)
}

export default {
  getGraphQLErrors,
}
