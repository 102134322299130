import styled, { css } from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import TextField, { TextFieldProps } from '@mui/material/TextField'

export type WrapperProps = MarginProps & LayoutProps

type StyledInputProps = TextFieldProps & {
  inverse?: boolean
}

const styledInputInverseCss = ({ inverse }: StyledInputProps) =>
  inverse &&
  css`
    & .MuiInputBase-root {
      color: ${themeGet('colors.neutral900')};
      background-color: ${themeGet('colors.neutral900Translucent')};

      & .MuiSvgIcon-root {
        color: ${themeGet('colors.neutral900')};
      }

      &:hover {
        fieldset {
          border-color: ${themeGet('colors.s')};
        }
      }
    }

    & label.Mui-focused {
      color: ${themeGet('colors.neutral900')};
    }

    & .MuiOutlinedInput-root {
      fieldset {
        border-color: transparent;
      }

      &.Mui-focused fieldset {
        border-color: ${themeGet('colors.neutral900')};
      }
    }
  `

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  ${margin}
  ${layout}
`

export const StyledInput = styled(TextField).withConfig({
  shouldForwardProp: prop => !['inverse'].includes(prop),
})<StyledInputProps>`
  width: 100%;

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-width: 1px;
    }
  }

  ${styledInputInverseCss}
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  position: relative;
`

export const PasswordIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  user-select: none;
  cursor: pointer;
`
