import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'
import { SearchableOptionType } from 'Types/searchable'

import { Events, KEY, State } from 'Services/Store/cooLogs'

export default function useCOOLogsState() {
  const { dispatch, [KEY]: state } = useStoreon<State, Events>(KEY)

  const updateDateRange = useCallback(
    (dateRange?: { from: string; to: string }) => {
      dispatch('cooLogs/update', {
        dateRange,
      })
    },
    [dispatch],
  )

  const updateFilter = useCallback(
    (filters?: SearchableOptionType) => {
      dispatch('cooLogs/update', {
        filters,
      })
    },
    [dispatch],
  )
  const clearFilter = useCallback(() => {
    dispatch('cooLogs/update', {
      filters: undefined,
    })
  }, [dispatch])

  const clear = useCallback(() => {
    dispatch('cooLogs/clear')
  }, [dispatch])

  return {
    state,
    updateDateRange,
    updateFilter,
    clearFilter,
    clear,
  }
}
