import { useCallback, useMemo } from 'react'

import { useMutation } from '@apollo/client'

import {
  UpdateCooDocument,
  UpdateCooMutationVariables,
} from 'GraphQL/Main/TypedDocuments'
import COOUpdater from 'GraphQL/Main/Updaters/coo'

import toast from 'Services/Toast'

function useCOO() {
  const [updateCooMutation, updateMutationResult] =
    useMutation(UpdateCooDocument)

  const updateMutation = useCallback(
    async (variables: UpdateCooMutationVariables) => {
      try {
        const result = await updateCooMutation({
          variables: {
            internalId: variables.internalId,
            isSearchable: variables.isSearchable,
          },
          update: (cache, { data }) =>
            COOUpdater.update({
              cache,
              checkCOO: data?.updateCOO,
            }),
        })

        const newCOO = result.data?.updateCOO

        if (!newCOO) {
          return null
        }

        toast.success({
          text: 'COO updated!',
        })

        return newCOO
      } catch (error) {
        toast.error({ text: 'error' })
        return null
      }
    },
    [updateCooMutation],
  )

  const { updateCOO } = useMemo(
    () => ({
      updateCOO: {
        mutate: updateMutation,
        loading: updateMutationResult.loading,
      },
    }),
    [updateMutationResult, updateMutation],
  )

  return { updateCOO }
}

export default useCOO
