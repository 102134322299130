import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useQuery } from '@apollo/client'
import { GridColDef } from '@mui/x-data-grid'
import COOUtils from 'Utils/coo'

import { DataGrid, Header } from 'Components/Blocks'
import Stamp from 'Components/Blocks/Stamp'
import TableContainer from 'Components/Blocks/TableContainer'
import { Column, Row, Text } from 'Components/UI'
import { Span } from 'Components/UI/Text'

import { ROOT, SETTINGS } from 'Constants/paths'

import { SearchLogListDocument } from 'GraphQL/Main/TypedDocuments'

import useResponsiveLayout from 'Hooks/useResponsiveLayout'

import { useScopedI18n } from 'Services/I18n'

import { ColumnsType, SearchLogsColumn } from './types'
import { processSearchLogsData } from './utils'

function SearchLogs() {
  const s = useScopedI18n('searchLogs')
  const { isMobilePortrait } = useResponsiveLayout()

  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const { data: searchLogListData, loading } = useQuery(SearchLogListDocument, {
    variables: { page },
  })

  const searchLogList = useMemo(
    () => searchLogListData?.searchLogList,
    [searchLogListData],
  )

  useEffect(() => {
    if (loading) {
      return
    }

    setTotalPages(searchLogList?.pages || 0)
  }, [loading, searchLogList])

  const searchLogs = useMemo(
    () => processSearchLogsData(searchLogList?.rows),
    [searchLogList],
  )

  const handleChangePage = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onChangePage']>
  >(page => {
    setPage(page)
  }, [])

  const columns: GridColDef<ColumnsType>[] = useMemo(
    () => [
      {
        field: SearchLogsColumn.CreatedAt,
        renderHeader: () => (
          <Text grow preWrap subheader1>
            {s('searchDate')}
          </Text>
        ),
        renderCell: row => <Text preWrap>{row.value || 'N/A'}</Text>,
        sortable: false,
        flex: 1,
      },

      {
        field: SearchLogsColumn.Query,
        renderHeader: () => <Text subheader1>{s('searchInput')}</Text>,
        renderCell: row => {
          const [refNumber, blankNumber] = COOUtils.splitSearchQuery(row.value)

          return (
            <Row
              flexDirection={isMobilePortrait ? 'column' : 'row'}
              fullWidth
              gap={3}
            >
              <Text ml={isMobilePortrait ? 0 : 4} width="100%">
                {s('refNumber')}
                <Span bold color="#1f3c92">
                  {refNumber || 'N/A'}
                </Span>
              </Text>

              <Text width="100%">
                {s('blankNumber')}
                <Span bold color="#1f3c92">
                  {blankNumber || 'N/A'}
                </Span>
              </Text>
            </Row>
          )
        },

        sortable: false,
        flex: 2,
        headerAlign: 'center',
      },
      {
        field: SearchLogsColumn.Found,
        renderHeader: () => (
          <Text preWrap subheader1>
            {s('searchStatus')}
          </Text>
        ),
        renderCell: row => (
          <Text
            color={row.value ? '#16833b' : '#e31c1c'}
            letterSpacing="0.03em"
            subheader2
          >
            {row.value
              ? 'valid'.toUpperCase()
              : 'invalid'.toUpperCase() || 'N/A'}
          </Text>
        ),
        sortable: false,
        flex: 1,
      },
    ],
    [isMobilePortrait, s],
  )

  const pageSize = searchLogList?.rows.length
  const totalEntries = searchLogList?.count

  return (
    <Column>
      <Header
        goHomePath={ROOT}
        goSettingsPath={SETTINGS}
        isBackButtonShown
        isSettingsButtonShown
      />

      <Column center my={8}>
        <TableContainer>
          <Stamp />

          <Column gap={8}>
            <Text center header1>
              {s('title')}
            </Text>
            {loading ? (
              <Text caption1>Loading...</Text>
            ) : (
              <DataGrid
                columns={columns}
                loading={loading}
                page={page}
                pageSize={pageSize}
                rows={searchLogs}
                totalEntries={totalEntries}
                totalPages={totalPages}
                onChangePage={handleChangePage}
              />
            )}
          </Column>
        </TableContainer>
      </Column>
    </Column>
  )
}

export default SearchLogs
