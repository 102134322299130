import { Operation, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

import { API } from 'Config'

import Shared from 'Services/Shared'

import createHttpLink from './createHTTPLink'
import WebSocketLink from './WebSocket'

function splitBySubscription({ query }: Operation) {
  // @ts-ignore
  const { kind, operation } = getMainDefinition(query)
  return kind === 'OperationDefinition' && operation === 'subscription'
}

function createAdminSubscriptionLink() {
  const link = new WebSocketLink(`${API.WS}/admin/graphql`)
  Shared.setSubscriptionLink(link)
  return link
}

function createMainSubscriptionLink() {
  const link = new WebSocketLink(`${API.WS}/graphql`)
  Shared.setSubscriptionLink(link)
  return link
}

export default function createRequestLink() {
  return split(
    ({ getContext }) => getContext().admin === true,
    split(
      splitBySubscription,
      // @ts-ignore
      createAdminSubscriptionLink(),
      createHttpLink(`${API.URL}/admin/graphql`),
    ),
    split(
      splitBySubscription,
      // @ts-ignore
      createMainSubscriptionLink(),
      createHttpLink(`${API.URL}/graphql`),
    ),
  )
}
