import { CooColumnsType } from 'Types/coos'
import Date from 'Utils/date'

import { ShortCooFragment } from 'GraphQL/Main/TypedDocuments'

function processCoosData(coos?: ShortCooFragment[]): CooColumnsType[] {
  if (!coos?.length) return []

  return coos.map(coo => ({
    id: coo.id,
    blanknumber: coo.data.blanknumber,
    createdAt: coo.createdAt ? Date.formatISO(coo.createdAt) : null,
    executedAt: coo.executedAt ? Date.formatISO(coo.executedAt) : null,
    refnumber: coo.data.refnumber,
    footprint: coo.footprint,
    transactionHash: coo.transactionHash,
    version: coo.version,
    isSearchable: coo.isSearchable,
    versions: coo.versions,
    internalId: coo.internalId,
  }))
}

export { processCoosData }
