import styled from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { MenuItemProps as MaterialMenuItemProps } from '@mui/material'
import MaterialMenuItem from '@mui/material/MenuItem'

import { createGetParam } from 'Theme'

interface VariantSizeProps {
  small?: boolean
}
interface VariantColorProps {
  active?: boolean
  selected?: boolean
  danger?: boolean
}

type MenuItemProps = LayoutProps &
  MarginProps &
  MaterialMenuItemProps &
  VariantSizeProps &
  VariantColorProps

const sizeParams = {
  height: {
    default: 54,
    small: themeGet('space.10'),
  },
  fontSize: {
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}
const colorParams = {
  color: {
    active: themeGet('colors.primary400'),
    danger: themeGet('colors.danger500'),
  },
  background: {
    selected: themeGet('colors.neutral800'),
  },
  hoverBackground: {
    danger: themeGet('colors.danger700'),
  },
}

const getSizeParam = createGetParam<typeof sizeParams, VariantSizeProps>(
  sizeParams,
)
const getColorParam = createGetParam<typeof colorParams, VariantColorProps>(
  colorParams,
)

const MenuItem = styled(MaterialMenuItem).withConfig({
  shouldForwardProp: prop =>
    !['active', 'selected', 'danger', 'small'].includes(prop),
})<MenuItemProps>`
  &.MuiButtonBase-root {
    font-size: ${getSizeParam('fontSize')}px;
    font-weight: ${themeGet('fontWeights.1')};
    line-height: 143%;
    padding: ${themeGet('space.2')}px ${themeGet('space.4')}px;
    height: ${getSizeParam('height')}px;
    background-color: ${getColorParam('background')};
    color: ${getColorParam('color')};
    transition: all ease ${themeGet('defaultTransitionTime')};

    &:hover {
      background-color: ${getColorParam('hoverBackground')};
    }

    & > svg {
      margin-right: ${themeGet('space.4')}px;
    }

    ${margin}
    ${layout}
  }
`

export default MenuItem
