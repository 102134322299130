import React, { ReactNode } from 'react'

import { Row } from 'Components/UI/Flex'

import CloseModalButton from './CloseModalButton'
import {
  Header,
  MainIconHolder,
  ModalButtons,
  StyledModal,
  StyledModalProps,
  Title,
} from './styles'

type Props = Omit<StyledModalProps, 'isOpen' | 'overlayClassName'> & {
  children?: React.ReactNode
  closeButtonDisabled?: boolean
  isOpen?: boolean
  title?: React.ReactNode
  mainIcon?: React.ReactNode
  danger?: boolean
  primary?: boolean
  success?: boolean
  warning?: boolean
  withoutCloseButton?: boolean
  withoutHeader?: boolean
  closeButtonAccessory?: ReactNode
  shouldCloseOnOverlayClick?: boolean
  onClose?: () => void
}

const rootElement =
  (window && window.document && window.document.getElementById('root')) ??
  undefined

function Modal({
  closeButtonDisabled,
  children,
  isOpen = false,
  title,
  mainIcon,
  danger,
  primary,
  success,
  warning,
  withoutCloseButton,
  withoutHeader,
  closeButtonAccessory,
  shouldCloseOnOverlayClick,
  onClose,
  ...rest
}: Props) {
  return (
    <StyledModal
      {...rest}
      appElement={rootElement}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onClose}
    >
      {!withoutHeader && (
        <Header>
          <Title>{title}</Title>
          {!withoutCloseButton && (
            <Row center gap={5}>
              {closeButtonAccessory}
              <CloseModalButton
                disabled={closeButtonDisabled}
                onClick={onClose}
              />
            </Row>
          )}
        </Header>
      )}
      {mainIcon && (
        <MainIconHolder
          danger={danger}
          mt={!title ? '-44px' : '-10px'}
          primary={primary}
          success={success}
          warning={warning}
        >
          {mainIcon}
        </MainIconHolder>
      )}
      {children}
    </StyledModal>
  )
}

Modal.Buttons = ModalButtons
Modal.CloseModalButton = CloseModalButton

export default Modal
