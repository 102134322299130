import { CooItemColumnsType } from 'Types/item'
import Utils from 'Utils'

import { CooDataItemFragment } from 'GraphQL/Main/TypedDocuments'

function processItemData(
  items?: CooDataItemFragment[] | null | undefined,
): CooItemColumnsType[] {
  if (!items) {
    return []
  }
  return items.map(item => {
    return {
      id: item.id,
      productName: item?.productName,
      hs_code: item?.hs_code,
      netWeight: Utils.Item.getItemsNetWeight(item),
      grossWeight: Utils.Item.getItemsGrossWeight(item),
      quantity: Utils.Item.getItemsQuantity(item),
      gu_pack: item?.gu_pack,
      pack: item?.pack,
    }
  })
}

export { processItemData }
