import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { Button, IconButton, Row, Text } from 'Components/UI'

import Auth from 'Services/Auth'
import { _ } from 'Services/I18n'

import { ChevronLeftIcon, Container, LogoutIcon, SettingsIcon } from './styles'

import LanguageMenu from '../LanguageMenu'

type Props = {
  goBackPath?: string
  goHomePath?: string
  goSettingsPath?: string
  goSearchLogsPath?: string
  isBackButtonShown?: boolean
  isHomeButtonShown?: boolean
  isSearchLogsButtonShown?: boolean
  isSettingsButtonShown?: boolean
  isTitleShown?: boolean
  title?: string
}

function Header({
  goBackPath,
  goHomePath,
  goSettingsPath,
  goSearchLogsPath,
  isBackButtonShown,
  isHomeButtonShown,
  isSearchLogsButtonShown,
  isSettingsButtonShown,
  isTitleShown,
  title,
}: Props) {
  const theme = useTheme()
  const history = useHistory()

  const color = useMemo(
    () => ({
      icon: themeGet('colors.white')({ theme }),
    }),
    [theme],
  )

  const handleBack = useCallback(() => {
    if (goBackPath) {
      history.push(goBackPath)
      return
    }

    history.goBack()
  }, [goBackPath, history])

  const handleGoHome = useCallback(() => {
    if (goHomePath) {
      history.push(goHomePath)
      return
    }

    history.goBack()
  }, [goHomePath, history])

  const handleGoSettings = useCallback(() => {
    if (goSettingsPath) {
      history.push(goSettingsPath)
      return
    }

    history.goBack()
  }, [goSettingsPath, history])

  const handleGoSearchLogs = useCallback(() => {
    if (goSearchLogsPath) {
      history.push(goSearchLogsPath)
      return
    }

    history.goBack()
  }, [goSearchLogsPath, history])

  return (
    <Container>
      <Row center fullWidth gap={5} ml={5}>
        {isBackButtonShown && (
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon color={color.icon} />
          </IconButton>
        )}{' '}
        {isHomeButtonShown && (
          <Button small variant="outlined" onClick={handleGoHome}>
            <Text>{_('home')}</Text>
          </Button>
        )}
        {isSearchLogsButtonShown && (
          <Button small variant="outlined" onClick={handleGoSearchLogs}>
            <Text>{_('searchLogs.title')}</Text>
          </Button>
        )}
      </Row>

      <Row justifyCenter>
        {isTitleShown && (
          <Text ellipsis header6 inverse maxWidth="100%">
            {title}
          </Text>
        )}
      </Row>

      <Row center gap={5} justifyEnd>
        {isSettingsButtonShown && (
          <IconButton onClick={handleGoSettings}>
            <SettingsIcon color={color.icon} />
          </IconButton>
        )}

        <LanguageMenu />

        <IconButton onClick={() => Auth.signOut()}>
          <LogoutIcon color={color.icon} />
        </IconButton>
      </Row>
    </Container>
  )
}

export default Header
