import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'

import DangerousIcon from '@mui/icons-material/Dangerous'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import { green, red } from '@mui/material/colors'
import Date from 'Utils/date'

import compact from 'lodash/compact'

import {
  Button,
  Column,
  LinkButton,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'

import { WEB3 } from 'Config'

import { CooRouteParams, ROOT } from 'Constants/paths'

import { CooFragment } from 'GraphQL/Main/TypedDocuments'

import useCOO from 'Hooks/useCOO'

import { _, useScopedI18n } from 'Services/I18n'
import cooContract from 'Services/Web3/Contracts/COO'

import CooItems from './CooItems'
import SearchableStatusRow from './SearchableStatusRow'
import { Table, ValidationRow } from './styles'
import { processItemData } from './utils'

type Props = {
  coo: CooFragment | undefined
}

function CooTable({ coo }: Props) {
  const s = useScopedI18n('coo')
  const { cooId } = useParams<CooRouteParams>()
  const { pathname } = useLocation()

  const [checking, setChecking] = useState<boolean>(true)
  const [cooDetails, setCOODetails] = useState<
    { version: number; footprint: string } | undefined
  >()
  const [jsonModalToOpen, setJsonModalToOpen] = useState(false)

  useEffect(() => {
    async function checkOnChain() {
      setChecking(true)
      try {
        const result = await cooContract.coo(cooId || coo?.id)

        setCOODetails({
          version: parseInt(result.version, 10),
          footprint: result.footprint?.toString(),
        })
      } catch {
        //
      }

      setChecking(false)
    }

    checkOnChain().then()
  }, [cooId, coo])

  const items = useMemo(() => processItemData(coo?.data?.items), [coo])

  const isRoot = pathname === ROOT

  const valid =
    cooDetails?.footprint === coo?.footprint &&
    cooDetails?.version === coo?.version

  const handleOpenModal = useCallback(() => {
    setJsonModalToOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setJsonModalToOpen(false)
  }, [])

  const handleOpenLink = useCallback((url: string) => {
    window.open(url, '_blank')
  }, [])

  const { updateCOO } = useCOO()

  const handleChangeSearchableStatus = useCallback(
    async (internalId: string, value?: boolean) => {
      await updateCOO.mutate({ internalId, isSearchable: !value })
    },
    [updateCOO],
  )

  return (
    <Column>
      <Column gap={8} mb={8}>
        {coo ? (
          <Column gap={8}>
            <Table>
              <tbody>
                <tr>
                  <td>{s('referenceNumber')}</td>
                  <td>
                    <strong>{coo?.data.refnumber}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{s('blankNumber')}</td>
                  <td>
                    <strong>{coo?.data.blanknumber}</strong>
                  </td>
                </tr>
                {!isRoot && (
                  <tr>
                    <td>Searchable Status</td>
                    <SearchableStatusRow
                      id={coo.internalId}
                      isSearchable={coo?.isSearchable}
                      onClick={handleChangeSearchableStatus}
                    />
                  </tr>
                )}
                <tr>
                  <td>{s('validation')}</td>
                  <ValidationRow checking={checking} valid={valid}>
                    <Row center justifyCenter spaceBetween>
                      {checking ? <Loader inverse /> : coo?.footprint}
                      {!checking && (
                        <Column>
                          {valid ? (
                            <Row center>
                              <Text color={green[600]} verticalAlign="center">
                                {s('trustPowered')}{' '}
                              </Text>
                              <GppGoodOutlinedIcon
                                fontSize="large"
                                sx={{ color: green[600] }}
                              />
                            </Row>
                          ) : (
                            <Row center gap={2}>
                              <Text verticalAlign="center">{s('invalid')}</Text>

                              <DangerousIcon
                                fontSize="large"
                                sx={{
                                  fill: red[600],
                                }}
                              />
                            </Row>
                          )}
                        </Column>
                      )}
                    </Row>
                  </ValidationRow>
                </tr>
                {!isRoot && (
                  <tr>
                    <td>{s('id')}</td>
                    <td>{coo?.data.id}</td>
                  </tr>
                )}

                <tr>
                  <td>{s('countryOfOrigin')}</td>
                  <td>Mongolia</td>
                </tr>
                <tr>
                  <td>{s('issuedBy')}</td>
                  <td>Mongolian National Chamber of Commerce and Industry</td>
                </tr>

                {!isRoot && (
                  <>
                    <tr>
                      <td>{s('hash')}</td>
                      <td>
                        {
                          <LinkButton
                            onClick={() =>
                              handleOpenLink(
                                `${WEB3.POLYGONSCAN_LINK_TX}${coo?.transactionHash}`,
                              )
                            }
                          >
                            {s('polygon')}
                          </LinkButton>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>{s('storeDBDate')}</td>
                      <td>{coo?.createdAt && Date.formatISO(coo.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>{s('storeBCDate')}</td>
                      <td>
                        {coo?.executedAt && Date.formatISO(coo.executedAt)}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>

            <Column gap={3}>
              <Text header4>{s('exporter.exporter')}</Text>
              <Table>
                <tbody>
                  <tr>
                    <td>{s('exporter.name')}</td>
                    <td>{coo?.data.expName}</td>
                  </tr>
                  <tr>
                    <td>{s('exporter.address')}</td>
                    <td>
                      {compact([
                        coo?.data.expCity,
                        coo?.data.expPostCode,
                        coo?.data.expAddress,
                      ]).join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td>{s('exporter.country')}</td>
                    <td>Mongolia</td>
                  </tr>
                </tbody>
              </Table>
            </Column>

            <Column gap={3}>
              <Text header4>{s('consignee.consignee')}</Text>
              <Table>
                <tbody>
                  <tr>
                    <td>{s('consignee.name')}</td>
                    <td>{coo?.data.conName}</td>
                  </tr>
                  <tr>
                    <td>{s('consignee.address')}</td>
                    <td>
                      {compact([
                        coo?.data.conCity,
                        coo?.data.conPostCode,
                        coo?.data.conAddress,
                      ]).join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td>{s('consignee.country')}</td>
                    <td>{coo?.data.conCountryName}</td>
                  </tr>
                </tbody>
              </Table>
            </Column>

            <Column gap={3}>
              <Text header4>{s('meansOfTransport')}</Text>
              <Table>
                <tbody>
                  <tr>
                    <td>{s('from')}</td>
                    <td>{coo?.data.from1} (Mongolia)</td>
                  </tr>
                  <tr>
                    <td>{s('to')}</td>
                    <td>
                      {coo?.data.to1} ({coo?.data.to1CountryName})
                    </td>
                  </tr>
                  <tr>
                    <td>{s('via')}</td>
                    <td>
                      {coo?.data.via1}{' '}
                      {coo?.data.via1CountryName &&
                        `(${coo?.data.via1CountryName})`}
                    </td>
                  </tr>

                  <tr>
                    <td>By </td>
                    <td>{coo?.data.by1TransportName}</td>
                  </tr>
                </tbody>
              </Table>
            </Column>

            <Column gap={3}>
              <Text header4>{s('remarks')}</Text>
              <Table>
                <tbody>
                  <tr>
                    <td>{s('contractNumber')}</td>
                    <td>{coo?.data.contractString}</td>
                  </tr>
                  <tr>
                    <td>{s('contractDate')}</td>
                    <td>
                      {coo?.data.contractDate &&
                        Date.formatMillis(coo.data.contractDate)}
                    </td>
                  </tr>
                  <tr>
                    <td>{s('invoiceNumber')}</td>
                    <td>{coo?.data.invoiceNumber}</td>
                  </tr>
                  <tr>
                    <td>{s('invoiceDate')}</td>
                    <td>
                      {coo?.data.invoiceDate &&
                        Date.formatMillis(coo.data.invoiceDate)}
                    </td>
                  </tr>

                  {/* TEMPORARILY COMMENTED. Should be either removed or uncommented. Waiting for client feedback */}
                  {/* <tr>
                <td>{s('totalPackages')}</td>
                <td>{coo?.data.totalPack}</td>
              </tr>
              <tr>
                <td>{s('totalWeight')}</td>
                <td>
                  {coo?.data.totalCWeigth ||
                    coo?.data.totalDWeigth}
                </td>
              </tr>
              <tr>
                <td>{s('storeDate')}</td>
                <td>
                  {coo?.data.created
                    ? DateTime.fromMillis(
                        parseInt(coo?.data.created, 10),
                      ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                    : null}
                </td>
              </tr> */}
                  {!isRoot && (
                    <tr>
                      <td>JSON</td>
                      <td>
                        <Button onClick={handleOpenModal}>Open JSON</Button>

                        <Modal
                          isOpen={jsonModalToOpen}
                          title={
                            <Text lineHeight={1.6}>
                              {JSON.stringify(coo?.data, null, ' ')}
                            </Text>
                          }
                          onClose={handleCloseModal}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Column>
          </Column>
        ) : (
          <Text caption1>{_('common.nothingFound')}</Text>
        )}
      </Column>

      {coo?.data?.items && <CooItems items={items} />}
    </Column>
  )
}

export default CooTable
