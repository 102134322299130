export enum SolutionKind {
  Polygon = 'polygon',
  LacChain = 'lacChain',
  Off = 'off',
}

export enum Field {
  IgnoreIncomingCalls = 'ignoreIncomingCalls',
  InvalidSearchNotification = 'invalidSearchNotification',
  ThresholdNotification = 'thresholdNotification',
  ThresholdAmount = 'thresholdAmount',
  ApiEndpoint = 'apiEndpoint',
  Wallet = 'wallet',
  SmartContractAddress = 'smartContractAddress',
  SMTPTo = 'smtpTo',
  SMTPUser = 'smtpUser',
  SMTPSenderName = 'smtpSenderName',
  SMTPPassword = 'smtpPassword',
  SMTPHost = 'smtpHost',
  SMTPPort = 'smtpPort',
}

export type IgnoreIncomingCallsTypes = {
  kind: SolutionKind
  enable: boolean
}

export type FormValues = {
  [Field.IgnoreIncomingCalls]?: IgnoreIncomingCallsTypes
  [Field.InvalidSearchNotification]: boolean
  [Field.ThresholdNotification]: boolean
  [Field.ThresholdAmount]: number
}
