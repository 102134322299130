import { DateTime } from 'luxon'

function formatISO(date: string) {
  return DateTime.fromISO(date).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
  )
}

function formatMillis(date: string) {
  return DateTime.fromMillis(parseInt(date, 10)).toLocaleString(
    DateTime.DATE_SHORT,
  )
}

export default { formatISO, formatMillis }
