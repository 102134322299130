import { StoreonModule } from 'storeon'

import Shared from 'Services/Shared'

const KEY = 'session'

interface StateValues {
  token?: string
  userId?: string
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'session/set': StateValues
  'session/clear': void
}

export const INITIAL_STATE: StateValues = {
  token: undefined,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))
  store.on('session/set', (state, payload) => {
    return {
      [KEY]: {
        ...state[KEY],
        ...payload,
      },
    }
  })
  store.on('session/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

function getState() {
  return Shared.getStore().get()[KEY]
}

function getToken() {
  return getState().token
}

function setToken(token?: string) {
  const currentToken = getState().token
  if (token === currentToken) return

  Shared.getStore().dispatch('session/set', { token })
  Shared.getSubscriptionLink().restart()
}

function getUserId() {
  return getState().userId
}

function setUserId(userId: string) {
  if (userId === getUserId()) return

  Shared.getStore().dispatch('session/set', { userId })
}

const Session = {
  getToken,
  setToken,
  getUserId,
  setUserId,
}

export { KEY, module, Session }
export type { Events, State }
