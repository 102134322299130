import React from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'

import { Column } from 'Components/UI'

import MainContainer from 'Containers/Main'

type Props = {
  route?: RouteConfig
}

function AdminLayout({ route }: Props) {
  return (
    <Column fullHeight>
      <MainContainer>{renderRoutes(route?.routes)}</MainContainer>
    </Column>
  )
}

export default AdminLayout
