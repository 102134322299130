import styled from 'styled-components'

import MaterialTabs from '@mui/material/Tabs'

const Tabs = styled(MaterialTabs)`
  &.MuiTabs-root {
    min-height: 0;
  }
`

export default Tabs
