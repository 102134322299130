import styled from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

type Props = MarginProps &
  LayoutProps & {
    disabled?: boolean
  }

const LinkButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: ${themeGet('fontSizes.2')}px;
  font-weight: ${themeGet('fontWeights.1')};
  color: ${themeGet('colors.primary500')};

  &:enabled:hover {
    color: ${themeGet('colors.primary300')};
  }

  &:enabled:active {
    color: ${themeGet('colors.primary500')};
  }

  &:disabled {
    cursor: default;
    color: ${themeGet('colors.text.muted')};
  }

  ${margin}
  ${layout}
`
export default LinkButton
