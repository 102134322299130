import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { createGetParam } from 'Theme'

export type VariantProps = {
  active?: boolean
  success?: boolean
  danger?: boolean
  disabled?: boolean
}

type StyledCaptionProps = MarginProps & {
  danger?: boolean
  success?: boolean
}

const variants = {
  borderColor: {
    default: themeGet('colors.divider.default'),
    active: themeGet('colors.primary500'),
    success: themeGet('colors.success500'),
    danger: themeGet('colors.danger500'),
  },
  captionColor: {
    default: themeGet('colors.text.muted'),
    success: themeGet('colors.success400'),
    danger: themeGet('colors.danger400'),
  },
}

const getParam = createGetParam<typeof variants, VariantProps>(variants)

export const StyledCaption = styled.div<StyledCaptionProps>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 12px;
  color: ${getParam('captionColor')};

  ${margin};

  > svg {
    fill: ${getParam('captionColor')};
  }
`
