import { useMemo } from 'react'

import { useMediaQuery } from 'styled-breakpoints/use-media-query'
import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

export default function useResponsiveLayout() {
  const theme = useTheme()

  const breakpoints = useMemo(
    () => themeGet('styledBreakpoints')({ theme }),
    [theme],
  )

  const isMobilePortrait = useMediaQuery(breakpoints?.down('small'))

  const isMobileLandscape = useMediaQuery(breakpoints?.down('medium'))

  const isTabletAndWider = useMediaQuery(breakpoints.up('medium'))

  const isDesktop = useMediaQuery(breakpoints?.up('large'))

  const isWide = useMediaQuery(breakpoints?.up('extraLarge'))

  return {
    isMobilePortrait,
    isMobileLandscape,
    isTabletAndWider,
    isDesktop,
    isWide,
  }
}
