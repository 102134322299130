import styled, { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import MaterialDrawer, {
  DrawerProps as MaterialDrawerProps,
} from '@mui/material/Drawer'

type DrawerProps = MaterialDrawerProps & {
  open?: boolean
}

const drawerOpenCss = ({ open }: DrawerProps) =>
  !open &&
  css`
    width: ${themeGet('sizes.sidebarCollapsed')}px;

    & .MuiDrawer-paper {
      width: ${themeGet('sizes.sidebarCollapsed')}px;
    }
  `

const Drawer = styled(MaterialDrawer)<DrawerProps>`
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;
  width: ${themeGet('sizes.sidebarOpened')}px;

  & .MuiDrawer-paper {
    transition: all ease ${themeGet('defaultTransitionTime')};
    width: ${themeGet('sizes.sidebarOpened')}px;
  }

  ${drawerOpenCss}
`

export default Drawer
