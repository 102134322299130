import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client'
import { Observer } from '@apollo/client/utilities'
import { v4 as uuid } from 'uuid'

interface FlowEntry {
  id: string
  operation: Operation
  forward: NextLink
  observer: Observer<FetchResult>
}

export default class FloodgateLink extends ApolloLink {
  private flow: FlowEntry[] = []
  private isOpen = true

  public request(
    operation: Operation,
    forward?: NextLink,
  ): Observable<FetchResult> | null {
    if (!forward) {
      return super.request(operation, forward)
    }

    if (this.isOpen || operation.getContext().skipFloodgate) {
      return forward(operation)
    }

    return new Observable<FetchResult>(observer => {
      this.flow.push({ id: uuid(), operation, forward, observer })
    })
  }

  public open() {
    this.isOpen = true

    this.flow.forEach(({ operation, forward, observer }) => {
      forward(operation).subscribe(observer)
    })
    this.flow = []
  }

  public close() {
    this.isOpen = false
  }
}
