import React, { useEffect, useState } from 'react'
import { fromAddress, setKey, setLanguage } from 'react-geocode'
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  Tooltip,
} from 'react-leaflet'

import compact from 'lodash/compact'

import { Column, Text } from 'Components/UI'

import { MAPS } from 'Config'

import { CooFragment } from 'GraphQL/Main/TypedDocuments'

import { PinIcon } from './styles'
import UpdateView from './UpdateView'
import { estimateZoom, findCenterSpherical, getDistance } from './utils'

setKey(MAPS.API_KEY)
setLanguage('en')

type Props = {
  coo: CooFragment | undefined
}

function Map({ coo }: Props) {
  const [locations, setLocations] = useState<any>([])
  const [zoom, setZoom] = useState<number>(2)
  const [centerLat, setCenterLat] = useState<number>(0)
  const [centerLng, setCenterLng] = useState<number>(0)

  useEffect(() => {
    const fetchCoordinates = async () => {
      const getCoordinates = async destinationName => {
        try {
          const response = await fromAddress(destinationName)

          const { lat, lng } = response.results[0].geometry.location

          return { lat, lng, destinationName }
        } catch (error) {
          console.error(error)
          return null
        }
      }

      const from = await getCoordinates(`${coo?.data.from1}, Mongolia`)
      const to = await getCoordinates(
        `${coo?.data.to1}, ${coo?.data.to1CountryName}`,
      )

      let via

      if (coo?.data.via1) {
        via = await getCoordinates(
          `${coo?.data.via1}, ${coo?.data.via1CountryName}`,
        )
      }

      if (!from && !to) return

      const distance = getDistance(from?.lat, from?.lng, to?.lat, to?.lng)

      if (distance === 0) setLocations([from, to])

      if (!distance) return

      const [centerLat, centerLng] = findCenterSpherical(
        from?.lat,
        from?.lng,
        to?.lat,
        to?.lng,
      )

      setCenterLat(centerLat)
      setCenterLng(centerLng)
      setZoom(estimateZoom(distance))
      setLocations(compact([from, via, to]))
    }

    fetchCoordinates().then()
  }, [coo, zoom])

  return (
    <Column>
      <Text header4 mb={4}>
        Map
      </Text>

      <MapContainer
        center={[centerLat, centerLng]}
        style={{ height: '400px', width: '100%' }}
        zoom={zoom}
      >
        <UpdateView center={[centerLat, centerLng]} zoom={zoom} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {locations.map(
          location =>
            location && (
              <Marker
                icon={PinIcon}
                key={`${location.lat},${location.lng}`}
                position={[location.lat, location.lng]}
              >
                <Tooltip>{location.destinationName}</Tooltip>
              </Marker>
            ),
        )}
        <Polyline pathOptions={{ color: '#0067d3' }} positions={[locations]} />
      </MapContainer>
    </Column>
  )
}

export default Map
