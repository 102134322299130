import { TypePolicies } from '@apollo/client'

export default {
  COO: {
    keyFields: ['id', 'version'],
  },
  Query: {
    fields: {},
  },
} as TypePolicies
