import Date from 'Utils/date'

import { ShortSearchLogFragment } from 'GraphQL/Main/TypedDocuments'

import { ColumnsType } from './types'

function processSearchLogsData(
  searchLogs?: ShortSearchLogFragment[],
): ColumnsType[] {
  if (!searchLogs?.length) return []

  return searchLogs.map(searchLog => ({
    id: searchLog.id,
    createdAt: searchLog.createdAt ? Date.formatISO(searchLog.createdAt) : null,
    found: searchLog?.found !== 0 && ' ',
    query: searchLog.query,
  }))
}

export { processSearchLogsData }
