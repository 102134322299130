import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  min-width: 100vw;
  height: 100vh;
  background-image: url('/mncci.jpg');
  background-size: cover;
`

export const StampImage = styled.img`
  transform: rotate(-11deg);
  width: 17%;
  height: auto;
  opacity: 0.7;
`
