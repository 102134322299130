import { ComponentProps } from 'react'

import styled, { css } from 'styled-components'
import {
  overflow,
  OverflowProps,
  space,
  SpaceProps,
  zIndex,
  ZIndexProps,
} from 'styled-system'
import themeGet from '@styled-system/theme-get'

import Adapter from './Adapter'

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 20px;
  z-index: 3;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
  font-weight: ${themeGet('fontWeights.2')};
  font-size: 18px;
  margin-right: auto;
  color: ${themeGet('colors.text.heading')};
`

export type StyledModalProps = ComponentProps<typeof Adapter> &
  OverflowProps &
  ZIndexProps

export const StyledModal = styled(Adapter).attrs<StyledModalProps>({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${themeGet('colors.neutral100')}B3;
    transition: all 100ms;
    z-index: 2000;
    outline: none;
    opacity: 0;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }

    ${zIndex}
  }

  .Modal {
    display: flex;
    flex-direction: column;
    max-height: 98vh;
    background: ${themeGet('colors.background.block')};
    box-shadow: 0 1px 64px -2px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 20px;
    color: ${themeGet('colors.text.body')};
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 100ms;
    width: 900px;
    max-width: 100vw;
    outline: none;
    opacity: 0;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }

    ${overflow}
  }

  & hr {
    margin: 20px -20px;
    border: none;
    background: ${themeGet('colors.divider.default')};
    height: 1px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const ModalButtons = styled.div<SpaceProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;

  & > * {
    width: 100%;
    min-width: min-content;
  }

  ${space}
`

interface MainIconHolderProps {
  primary?: boolean
  danger?: boolean
  success?: boolean
  warning?: boolean
}

const primaryCss = ({ primary }: MainIconHolderProps) =>
  primary &&
  css`
    color: ${themeGet('colors.primary500')};
  `

const dangerCss = ({ danger }: MainIconHolderProps) =>
  danger &&
  css`
    color: ${themeGet('colors.danger500')};
  `

const successCss = ({ success }: MainIconHolderProps) =>
  success &&
  css`
    color: ${themeGet('colors.success500')};
  `

const warningCss = ({ warning }: MainIconHolderProps) =>
  warning &&
  css`
    color: ${themeGet('colors.warning500')};
  `

export const MainIconHolder = styled.div<MainIconHolderProps & SpaceProps>`
  display: flex;
  justify-content: center;
  font-size: 64px;

  ${primaryCss}
  ${dangerCss}
  ${successCss}
  ${warningCss}
  ${space}
`
