import React, { ComponentProps, useCallback, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'

import { stampImage } from 'Assets/Images'
import { LogoSvg } from 'Assets/Svg'

import { Header } from 'Components/Blocks'
import { Button, Column, Input, Row } from 'Components/UI'

import { CheckCooDocument } from 'GraphQL/Main/TypedDocuments'

import useResponsiveLayout from 'Hooks/useResponsiveLayout'

import { _, useScopedI18n } from 'Services/I18n'

import { Container, StampImage } from './styles'

import Map from '../CooPage/Map'
import CooTable from '../CooTable'

function Admins() {
  const s = useScopedI18n('coo')

  const { isMobilePortrait, isWide } = useResponsiveLayout()

  const [blankNumber, setBlankNumber] = useState('')
  const [referenceNumber, setReferenceNumber] = useState('')

  const [checkCOO, { data, loading, called }] = useLazyQuery(CheckCooDocument)

  const handleSearchByNumbers = useCallback(async () => {
    if (!blankNumber || !referenceNumber) return
    await checkCOO({ variables: { blankNumber, referenceNumber, log: true } })
  }, [referenceNumber, blankNumber, checkCOO])

  const handleBlankNumberChange = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(event => {
    let searchKey = event.target.value.toUpperCase().trim()

    if (searchKey.indexOf(' ') === -1) {
      searchKey = `${searchKey.slice(0, 1)} ${searchKey.slice(1)}`
    }

    setBlankNumber(searchKey)
  }, [])

  const handleReferenceNumberChange = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(event => {
    let searchKey = event.target.value.trim()

    if (searchKey.indexOf(' ') === -1) {
      searchKey = `${searchKey.slice(0, 1)} ${searchKey.slice(1)}`
    }

    setReferenceNumber(searchKey)
  }, [])

  return (
    <Container>
      <Header />

      <Column
        backgroundColor="white"
        center
        maxWidth={isWide ? 1100 : 900}
        overflowY={'auto'}
        p={4}
        width={1}
      >
        <Row center flexShrink={0} fullWidth gap={8} mb={8} mt={5} spaceBetween>
          <LogoSvg height="auto" />
          <StampImage src={stampImage} />
        </Row>

        <Column center fullWidth mb={4} spaceBetween>
          <Row
            center
            flexDirection={isMobilePortrait ? 'column' : 'row'}
            fullWidth
            gap={4}
          >
            <Input
              label={s('referenceNumber')}
              placeholder={s('enterReferenceNumber')}
              renderBeforeElement={() => <SearchIcon />}
              value={referenceNumber}
              width={1}
              onChange={handleReferenceNumberChange}
            />

            <Input
              label={s('blankNumber')}
              placeholder={s('enterBlankNumber')}
              renderBeforeElement={() => <SearchIcon />}
              value={blankNumber}
              width={1}
              onChange={handleBlankNumberChange}
            />
          </Row>

          <Row center fullWidth gap={4} mt={4}>
            <Button
              disabled={!referenceNumber}
              small
              width={1}
              onClick={handleSearchByNumbers}
            >
              {_('common.search')}
            </Button>
          </Row>
        </Column>

        {loading && <div>Loading...</div>}

        {called && !loading && (
          <Column gap={8}>
            <CooTable coo={data?.checkCOO} />
            <Map coo={data?.checkCOO} />
          </Column>
        )}
      </Column>
    </Container>
  )
}

export default Admins
