import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { toasts } from './styles'

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    width: 100%;
   }

  body {
    color: ${themeGet('colors.text.body')};
    background-color: ${themeGet('colors.background.screen')};
    font-size: 14px;
    font-family: ${themeGet('font')};
    line-height: 20px;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    overflow: auto;
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
  }

  html, body, #root, #root > div {
    margin: 0;
    box-sizing: border-box;
    background-repeat: repeat;
  }


  ::selection {
    background: ${themeGet('colors.primary500')};
    color: white;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  pre {
    border: 1px solid ${themeGet('colors.divider.default')};
    background: ${themeGet('colors.background.block')};
    border-radius: 6px;
    padding: ${themeGet('space.5')}px;
    margin: ${themeGet('space.6')}px auto;
    max-width: 500px;
    text-align: left;
    font-size: ${themeGet('fontSizes.1')}px;
    font-weight: ${themeGet('fontWeights.0')};
    line-height: 24px;
  }

  input, textarea {
    display: block;
    margin: 10px auto;
  }

  ${toasts}
`
