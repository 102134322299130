import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const MainContainer = styled.main`
  height: 100%;
  transition: all ease ${themeGet('defaultTransitionTime')};
  z-index: 100;
`

export default MainContainer
