import React, { useMemo } from 'react'

import { GridColDef } from '@mui/x-data-grid'
import { CooItemColumns, CooItemColumnsType } from 'Types/item'

import { DataGrid } from 'Components/Blocks'
import { Column, Text } from 'Components/UI'

import { CooDataItem } from 'GraphQL/Main/TypedDocuments'

import { _, useScopedI18n } from 'Services/I18n'

type Props = {
  items: CooDataItem[]
}

function CooItems({ items }: Props) {
  const s = useScopedI18n('coo.items')

  const columns: GridColDef<CooItemColumnsType>[] = useMemo(
    () => [
      {
        field: CooItemColumns.ProductName,
        renderHeader: () => <Text preWrap>{s('productName')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 1.5,
        minWidth: 270,
        sortable: false,
      },
      {
        field: CooItemColumns.HsCode,
        renderHeader: () => <Text>{s('hsCode')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 0.7,
        minWidth: 100,
        sortable: false,
      },
      {
        field: CooItemColumns.NetWeight,
        renderHeader: () => <Text preWrap>{s('netWeight')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 0.7,
        minWidth: 100,
        sortable: false,
      },
      {
        field: CooItemColumns.GrossWeight,
        renderHeader: () => <Text preWrap>{s('grossWeight')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 0.7,
        minWidth: 100,
        sortable: false,
      },
      {
        field: CooItemColumns.Quantity,
        renderHeader: () => <Text>{s('quantity')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 0.7,
        minWidth: 100,
        sortable: false,
      },
      {
        field: CooItemColumns.MethodOfPacking,
        renderHeader: () => <Text preWrap>{s('methodOfPacking')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 0.6,
        minWidth: 100,
        sortable: false,
      },

      {
        field: CooItemColumns.TotalPacked,
        renderHeader: () => <Text preWrap>{s('totalPacked')}</Text>,
        renderCell: row => row.value || 'N/A',
        flex: 0.5,
        minWidth: 80,
        sortable: false,
      },
    ],
    [s],
  )

  return (
    <Column>
      <Text header4 mb={3}>
        {_('coo.packingList')}
      </Text>
      <DataGrid columns={columns} hideFooter rows={items} />
    </Column>
  )
}

export default CooItems
