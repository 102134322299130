import { ApolloClient } from '@apollo/client'

import FloodgateLink from './Apollo/Links/Floodgate'
import WebSocketLink from './Apollo/Links/WebSocket'
import { Store } from './Store'

export enum SharedService {
  ApolloClient = 'apolloClient',
  FloodgateLink = 'floodgateLink',
  SubscriptionLink = 'subscriptionLink',
  Store = 'store',
}

const SERVICES: Record<string, any> = {}

function getService<T = any>(service: SharedService) {
  if (!SERVICES[service]) throw new Error(`${service} not found in Shared`)

  return SERVICES[service] as T
}

function setApolloClient(client: ApolloClient<any>) {
  SERVICES[SharedService.ApolloClient] = client
}

function getApolloClient() {
  return getService<ApolloClient<any>>(SharedService.ApolloClient)
}

function setFloodgateLink(link: FloodgateLink) {
  SERVICES[SharedService.FloodgateLink] = link
}

function getFloodgateLink() {
  return getService<FloodgateLink>(SharedService.FloodgateLink)
}

function setSubscriptionLink(link: WebSocketLink) {
  SERVICES[SharedService.SubscriptionLink] = link
}

function getSubscriptionLink() {
  return getService<WebSocketLink>(SharedService.SubscriptionLink)
}

function setStore(store: Store) {
  SERVICES[SharedService.Store] = store
}

function getStore() {
  return getService<Store>(SharedService.Store)
}

export default {
  setApolloClient,
  getApolloClient,
  setFloodgateLink,
  getFloodgateLink,
  setSubscriptionLink,
  getSubscriptionLink,
  setStore,
  getStore,
}
