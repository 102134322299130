import { LinkProps } from 'react-router-dom'

import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import MaterialTab, { TabProps as MaterialTabProps } from '@mui/material/Tab'

type TabProps = MaterialTabProps & Partial<LinkProps>

const Tab = styled(MaterialTab)<TabProps>`
  &.MuiTab-root {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 0;
    padding: ${themeGet('space.5')}px;

    .MuiTab-iconWrapper {
      margin: 0;
    }

    &:hover {
      background-color: ${themeGet('colors.neutral800')};
    }
  }
`

export default Tab
