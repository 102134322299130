import styled from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'

import { MenuListProps as MaterialMenuListProps } from '@mui/material'
import MaterialMenu from '@mui/material/Menu'

type MenuListProps = LayoutProps & MarginProps & MaterialMenuListProps

const MenuList = styled(MaterialMenu)<MenuListProps>`
  &.MuiMenu-root {
    z-index: 3000;
  }

  & .MuiList-root {
    padding: 0;

    ${margin}
    ${layout}
  }
`

export default MenuList
