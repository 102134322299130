import React from 'react'

import { ThemeProvider as SCThemeProvider } from 'styled-components'

import DefaultTheme from './Default'

type Props = {
  children?: React.ReactNode
}

function ThemeProvider({ children }: Props) {
  return <SCThemeProvider theme={DefaultTheme}>{children}</SCThemeProvider>
}

export default ThemeProvider
