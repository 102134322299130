import styled from 'styled-components'

import { LogoSvg } from 'Assets/Svg'

export const StampImage = styled.img`
  transform: rotate(-11deg);
  width: 20%;
  height: auto;
  opacity: 0.7;
`

export const LogoImage = styled(LogoSvg)`
  width: 100%;
  height: auto;
`
