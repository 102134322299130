import React, { useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Button, Column, Link, Text } from 'Components/UI'

import { LOGIN, ROOT } from 'Constants/paths'

import { useAppContext } from 'Services/AppContext'

function NotFound() {
  const location = useLocation()
  const history = useHistory()
  const { me } = useAppContext()

  useLayoutEffect(() => {
    if (!me) {
      history.push(LOGIN)
    }
  }, [me, location, history])

  return (
    <Column center height="100vh" justifyCenter>
      <Text fontSize="64px" header1 heading>
        404
      </Text>
      <Text body3>Page Not Found</Text>

      <Button component={Link} mt={12} secondary to={ROOT} width="200px">
        HOME PAGE
      </Button>
    </Column>
  )
}

export default NotFound
