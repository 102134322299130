function splitSearchQuery(query?: string) {
  if (!query) return [null, null]

  const [ref, blank] = query.split('{BLANK}')

  return [ref.replace('{REF}', ''), blank]
}

const COOUtils = {
  splitSearchQuery,
}

export default COOUtils
