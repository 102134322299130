import { createStyledBreakpointsTheme } from 'styled-breakpoints'

import { Breakpoints } from 'Constants/ids'

import colors from './colors'
import sizes from './sizes'

const breakpoints = {
  extraSmall: `${Breakpoints.ExtraSmall}px`,
  small: `${Breakpoints.Small}px`,
  medium: `${Breakpoints.Medium}px`,
  large: `${Breakpoints.Large}px`,
  extraLarge: `${Breakpoints.ExtraLarge}px`,
} as const

const breakpointsTheme = createStyledBreakpointsTheme({
  breakpoints,
})

const DefaultTheme = {
  name: 'default',
  font: `Inter, BlinkMacSystemFont, sans-serif`,
  webfonts: ['Inter:400,500,600,700,900'],
  colors,
  sizes,
  defaultTransitionTime: '0.3s',
  //      0  1  2  3  4   5   6   7   8   9   10  11  12  13
  space: [0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48],
  //            0   1   2   3
  breakpoints: [
    `${Breakpoints.ExtraSmall}px`,
    `${Breakpoints.Small}px`,
    `${Breakpoints.Medium}px`,
    `${Breakpoints.Large}px`,
    `${Breakpoints.ExtraLarge}px`,
  ],
  styledBreakpoints: breakpointsTheme.breakpoints,
  //          0   1   2   3   4   5   6   7   8   9
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  //             0    1    2    3    4
  fontWeights: [400, 500, 600, 700, 900],
  //               0   1   2   3   4
  controlHeights: [24, 32, 40, 48, 56],
}

export default DefaultTheme
