import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import { useQuery } from '@apollo/client'

import { Header } from 'Components/Blocks'
import Stamp from 'Components/Blocks/Stamp'
import TableContainer from 'Components/Blocks/TableContainer'
import { Column, Text } from 'Components/UI'

import { CooRouteParams, LOG } from 'Constants/paths'

import { CooDocument } from 'GraphQL/Main/TypedDocuments'

import useResponsiveLayout from 'Hooks/useResponsiveLayout'

import { _ } from 'Services/I18n'

import Map from './Map'

import CooTable from '../CooTable'

function CooPage() {
  const { cooId, version } = useParams<CooRouteParams>()
  const { isMobilePortrait } = useResponsiveLayout()

  const { data, loading } = useQuery(CooDocument, {
    variables: { id: cooId, version: version ? parseInt(version, 10) : null },
  })

  const coo = useMemo(() => data?.coo, [data])

  return (
    <Column>
      <Header
        goBackPath={LOG}
        isBackButtonShown
        isTitleShown
        title={coo && `COO: ${coo.id}`}
      />

      <Column center my={8}>
        <TableContainer>
          <Stamp />

          <Column gap={8}>
            <Text center fontSize={isMobilePortrait ? 4 : ''} header2>
              {_('verificationOfCertificates')}
            </Text>

            {loading ? (
              <Text caption1>Loading...</Text>
            ) : (
              <Column gap={8}>
                <CooTable coo={coo} />
                <Map coo={coo} />
              </Column>
            )}
          </Column>
        </TableContainer>
      </Column>
    </Column>
  )
}

export default CooPage
