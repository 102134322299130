import { FieldMetaState } from 'react-final-form'

function hasError(meta: FieldMetaState<string>, checkDirty = false) {
  const { dirty, touched, error, submitError, dirtySinceLastSubmit } = meta
  const isError =
    (touched || (checkDirty && dirty)) &&
    (error || (submitError && !dirtySinceLastSubmit))

  return { hasError: isError, error: isError ? error || submitError : null }
}

export default {
  hasError,
}
