import React from 'react'

import { stampImage } from 'Assets/Images'

import { Row } from 'Components/UI'

import { LogoImage, StampImage } from './styles'

function Stamp() {
  return (
    <Row center gap="170px">
      <LogoImage />
      <StampImage src={stampImage} />
    </Row>
  )
}

export default Stamp
