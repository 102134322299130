import styled, { css } from 'styled-components'
import { margin, MarginProps, styleFn, system } from 'styled-system'

type Props = MarginProps & {
  color?: string
  size?: number
}

const SIZE = 6

export const backgroundColor = system({
  color: {
    property: 'backgroundColor',
    scale: 'colors',
  },
})

const sizeCss: styleFn = ({ size }: Props) =>
  size &&
  css`
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size / 2}px;
  `

const Dot = styled.div<Props>`
  display: flex;
  flex-shrink: 0;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: ${SIZE / 2}px;

  ${margin}
  ${backgroundColor}
  ${sizeCss}
`

export default Dot
