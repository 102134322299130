import React, { ComponentProps, useCallback } from 'react'

import { Button } from 'Components/UI/Buttons'
import Modal from 'Components/UI/Modal'

import { Content } from './styles'

type Props = Pick<
  ComponentProps<typeof Modal>,
  'closeButtonDisabled' | 'isOpen'
> & {
  okButtonDisabled?: boolean
  mainIcon?: React.ReactNode
  children?: React.ReactNode
  danger?: boolean
  primary?: boolean
  success?: boolean
  warning?: boolean
  isLoading?: boolean
  title?: React.ReactNode
  okText?: React.ReactNode
  cancelText?: React.ReactNode
  onCallback?: (ok: boolean) => void
}

function ConfirmModal({
  okButtonDisabled,
  danger,
  title,
  isOpen,
  isLoading,
  children,
  okText = 'Ok',
  cancelText = 'Cancel',
  onCallback,
  ...rest
}: Props) {
  const handleCancel = useCallback(() => {
    onCallback?.(false)
  }, [onCallback])

  const handleOk = useCallback(() => {
    onCallback?.(true)
  }, [onCallback])

  return (
    <Modal
      {...rest}
      danger={danger}
      isOpen={isOpen}
      title={title}
      onClose={handleCancel}
    >
      <Content>{children}</Content>
      <Modal.Buttons mt="20px">
        <Button disabled={isLoading} secondary small onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button
          danger={danger}
          disabled={isLoading || okButtonDisabled}
          small
          onClick={handleOk}
        >
          {okText}
        </Button>
      </Modal.Buttons>
    </Modal>
  )
}

export default ConfirmModal
