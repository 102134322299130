import { useCallback } from 'react'

import { I18n, Scope, TranslateOptions } from 'i18n-js'
import { StoreonStore } from 'storeon'
import { useStoreon } from 'storeon/react'

import get from 'lodash/get'

import { Language } from 'Constants/ids'

import { KEY as I18N_KEY } from 'Services/Store/i18n'

import mainEn from './translations/en.json'
import mainJa from './translations/ja.json'
import mainMn from './translations/mn.json'
import mainRu from './translations/ru.json'
import mainZh from './translations/zh.json'

const validLanguages = [
  Language.Mn,
  Language.Ch,
  Language.Ru,
  Language.En,
  Language.Jp,
]

const translations = {
  [Language.En]: mainEn,
  [Language.Mn]: mainMn,
  [Language.Ru]: mainRu,
  [Language.Ch]: mainZh,
  [Language.Jp]: mainJa,
}

export const i18n = new I18n(translations)

type InitTranslationsParams = {
  store: StoreonStore<any>
}

function initTranslations({ store }: InitTranslationsParams) {
  const i18nState = store.get()[I18N_KEY]
  let lang

  const storedLand = get(i18nState, 'locale')

  if (storedLand) {
    lang = storedLand
  } else {
    lang = window.navigator.language
    lang = lang ? lang.substring(0, 2) : ''
  }

  if (!validLanguages.includes(lang)) lang = Language.En

  i18n.locale = lang

  return i18n.locale
}

function _(key: Scope, options?: TranslateOptions) {
  return i18n.t(key, options)
}

type I18nParams = { t: Scope; options?: TranslateOptions }

function I18nComponent({ t, options }: I18nParams) {
  const { i18n: state } = useStoreon(I18N_KEY)
  return _(t, { ...options, locale: state.locale })
}

export function useScopedI18n(key: string) {
  return useCallback(
    (subKey: string, opts?: TranslateOptions) => _(`${key}.${subKey}`, opts),
    [key],
  )
}

export default I18nComponent
export { _, initTranslations, validLanguages }
