import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { useStoreon } from 'storeon/react'

import { Loader } from 'Components/UI'

import { MeDocument } from 'GraphQL/Main/TypedDocuments'

import AppContainer from 'Containers/App'

import { AppContext } from 'Services/AppContext'
import { Events, State } from 'Services/Store'
import { KEY as I18N_KEY } from 'Services/Store/i18n'
import { KEY as SESSION_KEY } from 'Services/Store/session'

function App() {
  const { [SESSION_KEY]: session, [I18N_KEY]: i18n } = useStoreon<
    State,
    Events
  >(I18N_KEY, SESSION_KEY)

  const { data, loading } = useQuery(MeDocument, {
    skip: !session.token,
    notifyOnNetworkStatusChange: true,
  })

  const me = data?.me || undefined

  const contextValue = useMemo(() => {
    return { me, locale: i18n.locale }
  }, [me, i18n])

  if (loading) return <Loader fullScreen size={70} />

  return (
    <AppContext.Provider value={contextValue}>
      <AppContainer />
    </AppContext.Provider>
  )
}

export default App
