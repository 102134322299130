import React, { useCallback, useRef, useState } from 'react'

import MaterialPagination from '@mui/material/Pagination'

import { MenuItem, MenuList, Row, Text } from 'Components/UI'

import useResponsiveLayout from 'Hooks/useResponsiveLayout'

import { useScopedI18n } from 'Services/I18n'

type Props = {
  pageSize?: number
  page?: number
  totalEntries?: number
  totalPages?: number
  loading?: boolean
  onChangePage?: (page: number) => void
}

function Pagination({
  pageSize,
  page,
  totalEntries,
  totalPages,
  loading,
  onChangePage,
}: Props) {
  const s = useScopedI18n('pagination')
  const iconButtonRef = useRef(null)
  const [menuListOpen, setMenuListOpen] = useState(false)

  const { isMobilePortrait } = useResponsiveLayout()

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])
  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])

  if (!totalPages) {
    return null
  }

  return (
    <Row
      center
      flexDirection={isMobilePortrait ? 'column' : 'row'}
      fullWidth
      justifyCenter
      py={4}
      spaceBetween
    >
      <Row center gap={2}>
        <MenuItem
          disabled={loading}
          ref={iconButtonRef}
          small
          onClick={handleOpenMenuList}
        >
          <Text caption1>
            {s('showing')}
            <b>{pageSize}</b>
            {s('outOf')}
            <b>{totalEntries}</b>
            {s('entries')}
          </Text>
        </MenuItem>
      </Row>

      <Row center justifyEnd>
        <MaterialPagination
          count={totalPages}
          disabled={loading}
          page={page !== undefined ? page + 1 : 0}
          onChange={(_, pageValue) => onChangePage?.(pageValue - 1)}
        />
      </Row>

      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      />
    </Row>
  )
}

export default Pagination
